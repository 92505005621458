import React from "react";
import { connect } from "components/Login/SignUp/signup-form";
import * as B from "react-bootstrap";
import styled from "styled-components";
import { Button } from "components/input/button";
import { Form } from "react-bootstrap";
import {
  NameField,
  SurnameField,
  EmailField,
  PromoCodeField,
  PhoneField,
  PasswordField,
  ConfirmPasswordField,
  ErrorFeedback,
  PrivacyLink as PrivacyLinkNoStyle,
  RegisterButton as RegisterButtonNoStyle,
} from "components/input/sign-up/fields";
import { Spinner } from "components/styles";

const BookingForm = ({ error, handleSubmit, submitting }: any) => (
  <Form onSubmit={handleSubmit}>
    <Form.Row>
      <B.Col>
        <NameField />
      </B.Col>
      <B.Col>
        <SurnameField />
      </B.Col>
    </Form.Row>
    <Form.Row>
      <B.Col>
        <PhoneField />
      </B.Col>
    </Form.Row>
    <Form.Row>
      <B.Col>
        <EmailField />
      </B.Col>
      {/* <B.Col>
                <PromoCodeField />
            </B.Col>  */}
    </Form.Row>
    <Form.Row>
      <B.Col>
        <PasswordField />
      </B.Col>
      <B.Col>
        <ConfirmPasswordField />
      </B.Col>
    </Form.Row>
    <ErrorFeedback error={error} />
    <Form.Row>
      <B.Col md={12}>
        <RegisterButtonWithLoading submitting={submitting} />
        <PrivacyLink />
      </B.Col>
    </Form.Row>
  </Form>
);

const RegisterButtonWithLoading = ({ submitting }: { submitting: boolean }) => {
  if (submitting) return <Spinner size="sm" />;
  else return <RegisterButton />;
};

const RegisterButton = styled(RegisterButtonNoStyle).attrs((props: any) => ({
  forwardedAs: Button,
  ...props,
}))`
  width: 100%;
  text-transform: uppercase;
`;

const PrivacyLink = styled(PrivacyLinkNoStyle)`
  text-align: center;
  color: #00426d;
  font-size: 14px;
  margin-top: 10px;
`;

export default connect(BookingForm);
