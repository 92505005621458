import { Field } from "components/input/field";
import { SubmitButton, TextWithTooltip } from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import React from "react";
import { Alert, Col, Modal, Row } from "react-bootstrap";
import { reduxForm, SubmissionError } from "redux-form";
import { TransferEventMotive } from "types/report";
import { removeUndefined } from "util/meta";
import { FIRESTORE } from "constants/firestore";
import { ScheduledTransfer } from "types/transfer";

export const ReportModal = ({
  show,
  handleClose,
  idTransfer,
  idDriver,
}: {
  show: boolean;
  handleClose: any;
  idTransfer: string;
  idDriver: string;
}) => {
  const handleSubmitReport = async (values: any) => {
    console.log(values.report);
    const transfer = (
      await FIRESTORE.DOCUMENT_SCHEDULED_TRANSFER(idDriver, idTransfer).get()
    ).data();
    let isLate: boolean;
    //hago el cálculo aprox y dejo que el back saque los minutos de demora
    if (transfer?.isUrgent) {
      //sea retorno o no, es urgent. Por lo tanto calculo de la hora de origen con margen de una hora
      isLate =
        transfer.destination.time.seconds + 3600 <
        ((new Date().getTime() / 1000) | 0);
    } else {
      //Solo calculo la hora de origen porque es imposible que un retorno no sea isUrgent
      isLate =
        transfer!.destination.time.seconds + 900 <
        ((new Date().getTime() / 1000 +
          transfer!.info.duration_origin_destination * 60) |
          0);
    }
    const manual: boolean = true;
    if (idDriver === "zdrEGB4N9dQuGBItxAqWlmTcVtn2") {
      await CALLABLE_FUNCTIONS.REPORT_CREATE_CABIFY(
        idTransfer,
        idDriver,
        values.report,
        isLate
      );
      console.log("estoy tarde?", isLate);
    } else {
      console.log("informe manual");
      await CALLABLE_FUNCTIONS.REPORT_CREATE_MANUAL(
        idTransfer,
        idDriver,
        values.report,
        isLate,
        manual
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Generar reporte</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>SOLO COMPLETAR LOS CAMPOS QUE SE QUIERAN MODIFICAR</h4> */}
        <FormEditTransferRedux onSubmit={handleSubmitReport} />
      </Modal.Body>
    </Modal>
  );
};

const FormEditTransfer = ({
  handleSubmit,
  submitSucceeded,
  submitting,
  error,
}: {
  handleSubmit: any;
  submitSucceeded: boolean;
  submitting: boolean;
  error: any;
}) => {
  if (submitSucceeded) {
    return <Alert variant="success">Traslado modificado</Alert>;
  }
  const reportOptions: string[] = [
    "Climatológico",
    "Corte ruta o manifestación",
    "Descompostura de móvil",
    "Decisión Personal",
    "Cancelación de turno",
    "Solicitar cambio de punto de encuentro",
    "Pasajero se niega a viajar",
    "No se logra hacer contacto con el pasajero",
  ];
  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error.message}</Alert>}</Row>

      <Row>
        <Col>
          <Field
            labelinput={<>Motivo Reporte</>}
            name="report"
            type="select-text"
          >
            <option disabled label="Seleccione una opción"></option>
            {reportOptions.map((e: string) => (
              <option
                key={reportOptions.indexOf(e)}
                value={reportOptions.indexOf(e)}
              >
                {e}
              </option>
            ))}
          </Field>
          <SubmitButton block submitting={submitting}>
            Enviar Reporte
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};

const FormEditTransferRedux = reduxForm({
  form: "edit-report",
})(FormEditTransfer);
