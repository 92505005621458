import ROUTES from "constants/routes";
import React, { useState, useEffect } from "react";
import {
  Padding,
  H2,
  Spinner,
  AsyncButton,
  ButtonWithTooltip,
} from "components/styles";
import { Row, Form, Col, Table, Button, Modal } from "react-bootstrap";
import { useQueryParam } from "util/hooks";
import { ModuleTransfer } from "types/module-transfer";
import { WithId } from "controllers/database";
import { getModule, getDistanceFromLatLonInKm } from "./pool_reducer";
import styled from "styled-components";
import moment from "moment";
import { TransferStage } from "types/transfer";
import Moment from "react-moment";
import { getFullAddress, MapPoint } from "controllers/map";
import queryString from "query-string";
import { useHistory } from "react-router";
import { DatePickerInput } from "components/input/date-picker";
import * as R from "react-router-dom";
import { moduleTransferStage } from "../module/header";
import { GetFullName } from "../..";
import { ZoneConfig } from "types/config";
import { getZonesConfig } from "components/Admin/Config/Global/reducer";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { useAsyncFunction } from "controllers/hooks";
import { SelectDriverInput } from "components/Admin/Sales/Purchases";
import { FirePoint } from "geofirex";

const AdminTransfersPool = () => (
  <MainContainerPool
    viewTransfer={ROUTES.ADMIN_MODULE_TRANSFER_VIEW}
    art={false}
  />
);

export const MainContainerPool = ({
  viewTransfer,
  art,
}: {
  viewTransfer: (a: any) => string;
  art: boolean;
}) => {
  const history = useHistory();
  const parsed = queryString.parse(window.location.search);
  const [loading, setLoading] = useState<boolean>(false);
  const [listOfCombinable, setListOfCombinable] = useState<
    (ModuleTransfer &
      WithId &
      string & { latitude: number; longitude: number })[][]
  >([]);
  const [startDate, setStartDate] = useQueryParam(
    moment().toDate(),
    "startDate",
    (v) => new Date(v),
    (v) => v.toISOString()
  );
  const [zones, setZones] = useState([] as ZoneConfig[]);
  const [zona, setZona] = useState<string>("TODAS LAS ZONAS");
  const [showScheduleModal, setShowScheduleModal] = useState<boolean>(false);
  const [transfers1, setTransfers1] = useState<string>("");
  const [transfers2, setTransfers2] = useState<string>("");
  const [origin, setOrigin] = useState<{
    latitude: number;
    longitude: number;
  }>();
  const [destination, setDestination] = useState<{
    latitude: number;
    longitude: number;
  }>();
  const [duration, setDuration] = useState<string>("");

  const zone = () => {
    getZonesConfig().then((d) => setZones(d));
  };

  const inicialSet = async (startDate: Date, zona: string) => {
    setLoading(true);
    let data: (ModuleTransfer & WithId)[] = (
      await getModule(startDate).get()
    ).docs.map((d) => ({
      ...d.data(),
      id: d.id,
    }));

    if (zona === "TODAS LAS ZONAS" || zona === "") {
      data = data.filter(
        (m) =>
          (m.pool === undefined || m.pool.poolGoing === false) &&
          /* m.art.id !== "V6WzgUy054XGnhTKGS6HTF44ej52" && */
          (m.status.stage === TransferStage.searching_driver ||
            m.status.stage === TransferStage.scheduled)
      );
    } else {
      data = data.filter(
        (m) =>
          (m.pool === undefined || m.pool.poolGoing === false) &&
          m.info.zona === zona &&
          /*  m.art.id !== "V6WzgUy054XGnhTKGS6HTF44ej52" && */
          (m.status.stage === TransferStage.searching_driver ||
            m.status.stage === TransferStage.scheduled)
      );
    }
    console.log(data);

    const conbination: any[] = [];
    let key = -1;
    //tiempo max de llegada tarde (min)
    const afterTimeMax = 15;

    //tiempo max de llegada antes de hora en origen
    const beforeTimeMax = 45;

    if (data.length > 0) {
      await Promise.all(
        data.map((transfer, index) => {
          console.log(index);
          const travel1 = transfer;
          for (let j = index + 1; j < data.length; j++) {
            const travel2 = data[j];
            //Distancia entre destinos
            const linealDistanceD1D2: number = getDistanceFromLatLonInKm(
              travel1.destination.geopoint.latitude,
              travel1.destination.geopoint.longitude,
              travel2.destination.geopoint.latitude,
              travel2.destination.geopoint.longitude
            );
            const durationD1D2: number = Math.round(
              (linealDistanceD1D2 * 1.4 * 60) / 30
            );
            //los traslados deben ser del mismo día y debe tener al menos 1 traslado buscando conductor
            if (
              moment(travel2.destination.time.toDate()).format("DD/MM/YYYY") ==
                moment(travel1.destination.time.toDate()).format(
                  "DD/MM/YYYY"
                ) &&
              travel1.status.doing_return === false &&
              travel2.status.doing_return === false &&
              durationD1D2 === 0 &&
              ((travel1.status.stage === TransferStage.searching_driver &&
                travel2.status.stage === TransferStage.scheduled) ||
                (travel2.status.stage === TransferStage.searching_driver &&
                  travel1.status.stage === TransferStage.scheduled) ||
                (travel2.status.stage === TransferStage.searching_driver &&
                  travel1.status.stage === TransferStage.searching_driver))
            ) {
              console.log("entra");
              //tiempo entre O1 y D1
              const durationO1D1 = Math.round(
                travel1.info.duration_origin_destination
              );
              //Tiempo entre O2 y D2
              const durationO2D2 = Math.round(
                travel2.info.duration_origin_destination
              );

              //Calculo tiempo estimado entre O2 y D1
              const linealDistanceO2D1: number = getDistanceFromLatLonInKm(
                travel1.destination.geopoint.latitude,
                travel1.destination.geopoint.longitude,
                travel2.origin.geopoint.latitude,
                travel2.origin.geopoint.longitude
              );
              const durationO2D1: number = Math.round(
                (linealDistanceO2D1 * 1.4 * 60) / 30
              );

              //Calculo tiempo estimado entre O1 y O2
              const linealDistanceO1O2: number = getDistanceFromLatLonInKm(
                travel1.origin.geopoint.latitude,
                travel1.origin.geopoint.longitude,
                travel2.origin.geopoint.latitude,
                travel2.origin.geopoint.longitude
              );
              const durationO1O2: number = Math.round(
                (linealDistanceO1O2 * 1.4 * 60) / 30
              );
              //Le agrego 15 min al segundo turno turno
              const destination2Max = moment(
                travel2.destination.time.toDate()
              ).add(afterTimeMax, "minutes");
              //la hora max a la q puede llegar el 1er paciente
              const destination1Max = moment(
                travel1.destination.time.toDate()
              ).add(afterTimeMax, "minutes");
              //resto 45 min al primer turno
              const destination1Min = moment(
                travel1.destination.time.toDate()
              ).subtract(beforeTimeMax, "minutes");
              //Lo mas temprano que puede llegar el 2do paciente
              const destination2Min = moment(
                travel2.destination.time.toDate()
              ).subtract(beforeTimeMax, "minutes");
              //Como el destino es el mismo, comprobamos cual es la ruta mas corta
              //O1 -> O2 -> D
              const durationO1O2D = durationO1O2 + durationO2D2;
              //O2 -> O1 -> D
              const durationO2O1D = durationO1O2 + durationO1D1;
              //O1 -> O2 -> D1
              const durationO1O2D1 = durationO1O2 + durationO2D1;
              //O2 -> D1 -> D2
              const durationO2D1D2 = durationO2D1 + durationD1D2;

              //tiempo max de viaje del pasajero 1
              let durationMaxP1: number = durationO1D1 * 1.5;
              //tiempo max de viaje del pasajero 2
              let durationMaxP2: number = durationO2D2 * 1.5;

              if (durationMaxP1 <= 30) durationMaxP1 = 30;
              if (durationMaxP2 <= 30) durationMaxP2 = 30;

              const timeMax = moment(destination1Min).add(
                durationD1D2,
                "minutes"
              );
              const timeMin = moment(destination2Min).subtract(
                durationD1D2,
                "minutes"
              );
              //Primero busca al paciente 2
              if (durationO1O2D > durationO2O1D) {
                //EL primer paciente puede llegar 45 min antes + tiempo de viaje entre destinos tiene q ser mayor q hora de turno2 - 45 min y menor q hora de turno2 +15 min
                if (
                  moment(timeMax.toDate()) <=
                    moment(destination2Max.toDate()) &&
                  moment(timeMin.toDate()) <= moment(destination1Max.toDate())
                ) {
                  //Verificamos que los pacientes no viajen mas tiempo del permitido
                  console.log(
                    durationMaxP2 >= durationO2O1D,
                    durationMaxP1 >= durationO1D1,
                    "VErificamos 2"
                  );

                  if (
                    durationMaxP2 >= durationO2O1D &&
                    durationMaxP1 >= durationO1D1
                  ) {
                    key = key + 1;
                    console.log(key);
                    conbination.push([
                      key,
                      travel1,
                      travel2,
                      "O2",
                      `${travel2.origin.geopoint.latitude},${travel2.origin.geopoint.longitude}/${travel1.origin.geopoint.latitude},${travel1.origin.geopoint.longitude}/${travel1.destination.geopoint.latitude}, ${travel1.destination.geopoint.longitude}`,
                      durationO2O1D.toString(),
                      {
                        latitude: travel2.origin.geopoint.latitude,
                        longitude: travel2.origin.geopoint.longitude,
                      },
                      {
                        latitude: travel1.destination.geopoint.latitude,
                        longitude: travel1.destination.geopoint.longitude,
                      },
                    ]);
                  }
                }
              } else {
                if (
                  moment(timeMax.toDate()) <=
                    moment(destination2Max.toDate()) &&
                  moment(timeMin.toDate()) <= moment(destination1Max.toDate())
                ) {
                  //Verificamos que los pacientes no viajen mas tiempo del permitido
                  if (
                    durationMaxP2 >= durationO2D2 &&
                    durationMaxP1 >= durationO1O2D
                  ) {
                    console.log(
                      durationMaxP2 >= durationO2D1D2,
                      durationMaxP1 >= durationO1O2D1
                    );

                    key = key + 1;
                    console.log(key);
                    conbination.push([
                      key,
                      travel1,
                      travel2,
                      "O1",
                      `${travel1.origin.geopoint.latitude},${travel1.origin.geopoint.longitude}/${travel2.origin.geopoint.latitude},${travel2.origin.geopoint.longitude}/${travel1.destination.geopoint.latitude}, ${travel1.destination.geopoint.longitude}`,
                      durationO1O2D.toString(),
                      {
                        latitude: travel1.origin.geopoint.latitude,
                        longitude: travel1.origin.geopoint.longitude,
                      },
                      {
                        latitude: travel1.origin.geopoint.latitude,
                        longitude: travel1.origin.geopoint.longitude,
                      },
                    ]);
                  }
                }
              }
            } else if (
              moment(travel2.destination.time.toDate()).format("DD/MM/YYYY") ==
                moment(travel1.destination.time.toDate()).format(
                  "DD/MM/YYYY"
                ) &&
              durationD1D2 === 0 &&
              travel1.status.doing_return === false &&
              travel2.status.doing_return === false &&
              ((travel1.status.stage === TransferStage.searching_driver &&
                travel2.status.stage === TransferStage.scheduled) ||
                (travel2.status.stage === TransferStage.searching_driver &&
                  travel1.status.stage === TransferStage.scheduled) ||
                (travel2.status.stage === TransferStage.searching_driver &&
                  travel1.status.stage === TransferStage.searching_driver))
            ) {
              //tiempo entre O1 y D1
              const durationO1D1 = Math.round(
                travel1.info.duration_origin_destination
              );
              //Tiempo entre O2 y D2
              const durationO2D2 = Math.round(
                travel2.info.duration_origin_destination
              );

              //Calculo tiempo estimado entre O2 y D1
              const linealDistanceO2D1: number = getDistanceFromLatLonInKm(
                travel1.destination.geopoint.latitude,
                travel1.destination.geopoint.longitude,
                travel2.origin.geopoint.latitude,
                travel2.origin.geopoint.longitude
              );
              const durationO2D1: number = Math.round(
                (linealDistanceO2D1 * 1.4 * 60) / 30
              );

              //Calculo tiempo estimado entre O1 y O2
              const linealDistanceO1O2: number = getDistanceFromLatLonInKm(
                travel1.origin.geopoint.latitude,
                travel1.origin.geopoint.longitude,
                travel2.origin.geopoint.latitude,
                travel2.origin.geopoint.longitude
              );
              const durationO1O2: number = Math.round(
                (linealDistanceO1O2 * 1.4 * 60) / 30
              );
              //Le agrego 15 min al segundo turno turno
              const destination2Max = moment(
                travel2.destination.time.toDate()
              ).add(afterTimeMax, "minutes");
              //la hora max a la q puede llegar el 1er paciente
              const destination1Max = moment(
                travel1.destination.time.toDate()
              ).add(afterTimeMax, "minutes");
              //resto 45 min al primer turno
              const destination1Min = moment(
                travel1.destination.time.toDate()
              ).subtract(beforeTimeMax, "minutes");
              //Lo mas temprano que puede llegar el 2do paciente
              const destination2Min = moment(
                travel2.destination.time.toDate()
              ).subtract(beforeTimeMax, "minutes");

              //O1 -> O2 -> D1
              const durationO1O2D1 = durationO1O2 + durationO2D1;
              //O2 -> D1 -> D2
              const durationO2D1D2 = durationO2D1 + durationD1D2;
              //O1 -> O2 -> D2 - D1
              const durationO1O2D2D1 =
                durationO1O2 + durationO2D2 + durationD1D2;
              const durationO1O2D1D2 =
                durationD1D2 + durationO2D1 + durationD1D2;
              //tiempo max de viaje del pasajero 1
              let durationMaxP1: number = durationO1D1 * 1.5;
              //tiempo max de viaje del pasajero 2
              let durationMaxP2: number = durationO2D2 * 1.5;

              if (durationMaxP1 <= 30) durationMaxP1 = 30;
              if (durationMaxP2 <= 30) durationMaxP1 = 30;

              const timeMax = moment(destination1Min).add(
                durationD1D2,
                "minutes"
              );
              const timeMin = moment(destination2Min).subtract(
                durationD1D2,
                "minutes"
              );
              //EL primer paciente puede llegar 45 min antes + tiempo de viaje entre destinos tiene q ser mayor q hora de turno2 - 45 min y menor q hora de turno2 +15 min
              if (
                moment(timeMax.toDate()) <= moment(destination2Max.toDate()) &&
                moment(timeMin.toDate()) <= moment(destination1Max.toDate())
              ) {
                //Verificamos que los pacientes no viajen mas tiempo del permitido
                if (
                  durationMaxP2 >= durationO2D1D2 &&
                  durationMaxP1 >= durationO1O2D1 &&
                  durationMaxP2 >= durationO2D2 &&
                  durationMaxP1 >= durationO1O2D2D1
                ) {
                  console.log("2 destinos posibles");
                  //verificamos el camino mas corto
                  if (durationO1O2D2D1 > durationO1O2D1D2) {
                    console.log("camino mas corto por d2");
                    key = key + 1;
                    console.log(key);
                    conbination.push([
                      key,
                      travel1,
                      travel2,
                      "D2",
                      `${travel1.origin.geopoint.latitude},${travel1.origin.geopoint.longitude}/${travel2.origin.geopoint.latitude},${travel2.origin.geopoint.longitude}/${travel2.destination.geopoint.latitude}, ${travel2.destination.geopoint.longitude}/${travel1.destination.geopoint.latitude},${travel1.destination.geopoint.longitude}`,
                      durationO1O2D2D1.toString(),
                      {
                        latitude: travel1.origin.geopoint.latitude,
                        longitude: travel1.origin.geopoint.longitude,
                      },
                      {
                        latitude: travel1.destination.geopoint.latitude,
                        longitude: travel1.destination.geopoint.longitude,
                      },
                    ]);
                  } else {
                    console.log("camio mas corto por D1");
                    key = key + 1;
                    console.log(key);
                    conbination.push([
                      key,
                      travel1,
                      travel2,
                      "D1",
                      `${travel1.origin.geopoint.latitude},${travel1.origin.geopoint.longitude}/${travel2.origin.geopoint.latitude},${travel2.origin.geopoint.longitude}/${travel1.destination.geopoint.latitude}, ${travel1.destination.geopoint.longitude}/${travel2.destination.geopoint.latitude}, ${travel2.destination.geopoint.longitude}`,
                      durationO1O2D1D2.toString(),
                      {
                        latitude: travel1.origin.geopoint.latitude,
                        longitude: travel1.origin.geopoint.longitude,
                      },
                      {
                        latitude: travel2.destination.geopoint.latitude,
                        longitude: travel2.destination.geopoint.longitude,
                      },
                    ]);
                  }
                } else if (
                  durationMaxP2 >= durationO2D1D2 &&
                  durationMaxP1 >= durationO1O2D1
                ) {
                  console.log("Camino por D1");
                  key = key + 1;
                  console.log(key);
                  conbination.push([
                    key,
                    travel1,
                    travel2,
                    "D1",
                    `${travel1.origin.geopoint.latitude},${travel1.origin.geopoint.longitude}/${travel2.origin.geopoint.latitude},${travel2.origin.geopoint.longitude}/${travel1.destination.geopoint.latitude}, ${travel1.destination.geopoint.longitude}/${travel2.destination.geopoint.latitude}, ${travel2.destination.geopoint.longitude}`,
                    durationO1O2D1D2.toString(),
                    {
                      latitude: travel1.origin.geopoint.latitude,
                      longitude: travel1.origin.geopoint.longitude,
                    },
                    {
                      latitude: travel2.destination.geopoint.latitude,
                      longitude: travel2.destination.geopoint.longitude,
                    },
                  ]);
                } else if (
                  durationMaxP2 >= durationO2D2 &&
                  durationMaxP1 >= durationO1O2D2D1
                ) {
                  key = key + 1;
                  console.log(key);
                  conbination.push([
                    key,
                    travel1,
                    travel2,
                    "D2",
                    `${travel1.origin.geopoint.latitude},${travel1.origin.geopoint.longitude}/${travel2.origin.geopoint.latitude},${travel2.origin.geopoint.longitude}/${travel2.destination.geopoint.latitude}, ${travel2.destination.geopoint.longitude}/${travel1.destination.geopoint.latitude}, ${travel1.destination.geopoint.longitude}`,
                    durationO1O2D2D1.toString(),
                    {
                      latitude: travel1.origin.geopoint.latitude,
                      longitude: travel1.origin.geopoint.longitude,
                    },
                    {
                      latitude: travel1.destination.geopoint.latitude,
                      longitude: travel1.destination.geopoint.longitude,
                    },
                  ]);
                }
              }
            } else if (
              moment(travel2.destination.time.toDate()).format("DD/MM/YYYY") ==
                moment(travel1.destination.time.toDate()).format(
                  "DD/MM/YYYY"
                ) &&
              (travel1.status.doing_return === true ||
                travel2.status.doing_return === true) &&
              ((travel1.status.stage === TransferStage.searching_driver &&
                travel2.status.stage === TransferStage.scheduled) ||
                (travel2.status.stage === TransferStage.searching_driver &&
                  travel1.status.stage === TransferStage.scheduled) ||
                (travel2.status.stage === TransferStage.searching_driver &&
                  travel1.status.stage === TransferStage.searching_driver))
            ) {
              console.log("es un retorno");
              //tiempo entre O1 y D1
              const durationO1D1 = Math.round(
                travel1.info.duration_origin_destination
              );
              //Tiempo entre O2 y D2
              const durationO2D2 = Math.round(
                travel2.info.duration_origin_destination
              );
              //Calculo tiempo estimado entre O1 y D2
              const linealDistanceO1D2: number = getDistanceFromLatLonInKm(
                travel1.origin.geopoint.latitude,
                travel1.origin.geopoint.longitude,
                travel2.destination.geopoint.latitude,
                travel2.destination.geopoint.longitude
              );
              const durationO1D2: number = Math.round(
                (linealDistanceO1D2 * 1.4 * 60) / 30
              );

              //Calculo tiempo estimado entre O2 y D1
              const linealDistanceO2D1: number = getDistanceFromLatLonInKm(
                travel1.destination.geopoint.latitude,
                travel1.destination.geopoint.longitude,
                travel2.origin.geopoint.latitude,
                travel2.origin.geopoint.longitude
              );
              const durationO2D1: number = Math.round(
                (linealDistanceO2D1 * 1.4 * 60) / 30
              );

              //Calculo tiempo estimado entre O1 y O2
              const linealDistanceO1O2: number = getDistanceFromLatLonInKm(
                travel1.origin.geopoint.latitude,
                travel1.origin.geopoint.longitude,
                travel2.origin.geopoint.latitude,
                travel2.origin.geopoint.longitude
              );
              const durationO1O2: number = Math.round(
                (linealDistanceO1O2 * 1.4 * 60) / 30
              );
              let destination1Min;
              let destination2Max;
              let destination1Max;
              let destination2Min;
              //El traslado 1es un retorno
              if (
                travel1.status.doing_return === true &&
                travel2.status.doing_return === false
              ) {
                destination1Max = moment(
                  travel1.destination.time.toDate()
                ).subtract(durationO1D1, "minutes");
                destination2Max = moment(
                  travel2.destination.time.toDate()
                ).subtract(beforeTimeMax);
                destination2Min = moment(travel2.destination.time.toDate()).add(
                  afterTimeMax
                );
                //O1 -> O2 -> D1
                const durationO1O2D1 = durationO1O2 + durationO2D1;
                //O2 -> D1 -> D2
                const durationO2D1D2 = durationO2D1 + durationD1D2;
                //O1 -> O2 -> D2 - D1
                const durationO1O2D2D1 =
                  durationO1O2 + durationO2D2 + durationD1D2;
                const durationO1O2D1D2 =
                  durationO1O2 + durationO2D1 + durationD1D2;

                //tiempo max de viaje del pasajero 1
                let durationMaxP1: number = durationO1D1 * 1.5;
                //tiempo max de viaje del pasajero 2
                let durationMaxP2: number = durationO2D2 * 1.5;

                if (durationMaxP1 <= 30) durationMaxP1 = 30;
                if (durationMaxP2 <= 30) durationMaxP1 = 30;

                if (
                  moment(destination1Max.toDate()).add(durationD1D2) >=
                    moment(destination2Max.toDate()) &&
                  moment(destination1Max.toDate()).add(durationD1D2) <=
                    moment(destination2Min.toDate())
                ) {
                  console.log(
                    moment(destination1Max.toDate()).add(durationD1D2),
                    moment(destination2Max.toDate()),
                    moment(destination2Min.toDate()),
                    "El 1er traslado es un retorno"
                  );
                  //Verificamos que los pacientes no viajen mas tiempo del permitido
                  if (
                    durationMaxP2 >= durationO2D1D2 &&
                    durationMaxP1 >= durationO1O2D1
                  ) {
                    key = key + 1;
                    console.log(key);
                    conbination.push([
                      key,
                      travel1,
                      travel2,
                      "D1",
                      `${travel1.destination.geopoint.latitude}, ${travel1.destination.geopoint.longitude}/${travel2.origin.geopoint.latitude}, ${travel2.origin.geopoint.longitude}/${travel1.origin.geopoint.latitude}, ${travel1.origin.geopoint.longitude}/${travel2.destination.geopoint.latitude}, ${travel2.destination.geopoint.longitude}`,
                      durationO1O2D1D2.toString(),
                      {
                        latitude: travel2.origin.geopoint.latitude,
                        longitude: travel2.origin.geopoint.longitude,
                      },
                      {
                        latitude: travel2.destination.geopoint.latitude,
                        longitude: travel2.destination.geopoint.longitude,
                      },
                    ]);
                  } else if (
                    durationMaxP2 >= durationO2D2 &&
                    durationMaxP1 >= durationO1O2D2D1
                  ) {
                    console.log(
                      durationMaxP2 >= durationO2D2,
                      durationMaxP1 >= durationO1O2D2D1
                    );

                    key = key + 1;
                    console.log(key);
                    conbination.push([
                      key,
                      travel1,
                      travel2,
                      "D2",
                      `${travel1.destination.geopoint.latitude}, ${travel1.destination.geopoint.longitude}/${travel2.origin.geopoint.latitude}, ${travel2.origin.geopoint.longitude}/${travel2.destination.geopoint.latitude}, ${travel2.destination.geopoint.longitude}/${travel1.origin.geopoint.latitude}, ${travel1.origin.geopoint.longitude}`,
                      durationO1O2D2D1.toString(),
                      {
                        latitude: travel1.destination.geopoint.latitude,
                        longitude: travel1.destination.geopoint.longitude,
                      },
                      {
                        latitude: travel1.origin.geopoint.latitude,
                        longitude: travel1.origin.geopoint.longitude,
                      },
                    ]);
                  }
                }
              } else if (
                travel1.status.doing_return === false &&
                travel2.status.doing_return === true
              ) {
                destination2Max = moment(
                  travel2.destination.time.toDate()
                ).subtract(durationO1D1, "minutes");
                destination1Max = moment(
                  travel1.destination.time.toDate()
                ).subtract(beforeTimeMax);
                destination1Min = moment(travel1.destination.time.toDate()).add(
                  afterTimeMax
                );
                //O1 -> O2 -> D1
                const durationO1O2D1 = durationO1O2 + durationO2D1;
                //O2 -> D1 -> D2
                const durationO2D1D2 = durationO2D1 + durationD1D2;
                //O1 -> O2 -> D2 - D1
                const durationO1O2D2D1 =
                  durationO1O2 + durationO2D2 + durationD1D2;
                const durationO1O2D1D2 =
                  durationO1O2 + durationO2D1 + durationD1D2;

                //tiempo max de viaje del pasajero 1
                let durationMaxP1: number = durationO1D1 * 1.5;
                //tiempo max de viaje del pasajero 2
                let durationMaxP2: number = durationO2D2 * 1.5;

                if (durationMaxP1 <= 30) durationMaxP1 = 30;
                if (durationMaxP2 <= 30) durationMaxP1 = 30;

                if (
                  moment(destination1Max.toDate()).add(durationD1D2) <=
                    moment(destination2Max.toDate()) &&
                  moment(destination1Min.toDate()).add(durationD1D2) <=
                    moment(destination2Max.toDate())
                ) {
                  console.log(
                    moment(destination1Max.toDate()).add(durationD1D2),
                    moment(destination2Max.toDate()),
                    moment(destination2Max.toDate()),
                    "El 2do traslado es un retorno"
                  );
                  //Verificamos que los pacientes no viajen mas tiempo del permitido
                  if (
                    durationMaxP2 >= durationO2D1D2 &&
                    durationMaxP1 >= durationO1O2D1
                  ) {
                    key = key + 1;
                    console.log(key);
                    conbination.push([
                      key,
                      travel1,
                      travel2,
                      "D1",
                      `${travel1.origin.geopoint.latitude}, ${travel1.origin.geopoint.longitude}/${travel2.destination.geopoint.latitude}, ${travel2.destination.geopoint.longitude}/${travel1.destination.geopoint.latitude}, ${travel1.destination.geopoint.longitude}/${travel2.origin.geopoint.latitude}, ${travel2.origin.geopoint.longitude}`,
                      durationO1O2D1D2.toString(),
                      {
                        latitude: travel1.origin.geopoint.latitude,
                        longitude: travel1.origin.geopoint.longitude,
                      },
                      {
                        latitude: travel2.origin.geopoint.latitude,
                        longitude: travel2.origin.geopoint.longitude,
                      },
                    ]);
                  } else if (
                    durationMaxP2 >= durationO2D2 &&
                    durationMaxP1 >= durationO1O2D2D1
                  ) {
                    console.log(
                      durationMaxP2 >= durationO2D2,
                      durationMaxP1 >= durationO1O2D2D1
                    );

                    key = key + 1;
                    console.log(key);
                    conbination.push([
                      key,
                      travel1,
                      travel2,
                      "D2",
                      `${travel1.origin.geopoint.latitude}, ${travel1.origin.geopoint.longitude}/${travel2.destination.geopoint.latitude}, ${travel2.destination.geopoint.longitude}/${travel2.origin.geopoint.latitude}, ${travel2.origin.geopoint.longitude}/${travel1.destination.geopoint.latitude}, ${travel1.destination.geopoint.longitude}`,
                      durationO1O2D2D1.toString(),
                      {
                        latitude: travel1.origin.geopoint.latitude,
                        longitude: travel1.origin.geopoint.longitude,
                      },
                      {
                        latitude: travel1.destination.geopoint.latitude,
                        longitude: travel1.destination.geopoint.longitude,
                      },
                    ]);
                  }
                }
              } else if (
                travel1.status.doing_return === true &&
                travel2.status.doing_return === true
              ) {
                //O1 -> O2 -> D1
                const durationO1O2D1 = durationO1O2 + durationO2D1;
                //O2 -> D1 -> D2
                const durationO2D1D2 = durationO2D1 + durationD1D2;
                //O1 -> O2 -> D2 - D1
                const durationO1O2D2D1 =
                  durationO1O2 + durationO2D2 + durationD1D2;
                const durationO1O2D1D2 =
                  durationO1O2 + durationO2D1 + durationD1D2;
                destination2Max = moment(
                  travel2.destination.time.toDate()
                ).subtract(durationO2D2, "minutes");
                destination1Max = moment(
                  travel1.destination.time.toDate()
                ).subtract(durationO1D1, "minutes");
                //tiempo max de viaje del pasajero 1
                let durationMaxP1: number = durationO1D1 * 1.5;
                //tiempo max de viaje del pasajero 2
                let durationMaxP2: number = durationO2D2 * 1.5;

                if (durationMaxP1 <= 50) durationMaxP1 = 50;
                if (durationMaxP2 <= 50) durationMaxP1 = 50;

                if (
                  moment(travel1.destination.time.toDate()).add(durationD1D2) <=
                  moment(travel2.destination.time.toDate())
                ) {
                  console.log(
                    moment(travel1.destination.time.toDate()).add(durationD1D2),
                    moment(travel2.destination.time.toDate()),
                    "Los dos traslados son retornos"
                  );
                  //Verificamos que los pacientes no viajen mas tiempo del permitido
                  if (
                    durationMaxP2 >= durationO2D1D2 &&
                    durationMaxP1 >= durationO1O2D1
                  ) {
                    key = key + 1;
                    console.log(key);
                    conbination.push([
                      key,
                      travel1,
                      travel2,
                      "D1",
                      `${travel1.destination.geopoint.latitude}, ${travel1.destination.geopoint.longitude}/${travel2.destination.geopoint.latitude}, ${travel2.destination.geopoint.longitude}/${travel1.origin.geopoint.latitude}, ${travel1.origin.geopoint.longitude}/${travel2.origin.geopoint.latitude}, ${travel2.origin.geopoint.longitude}`,
                      durationO1O2D1D2.toString(),
                      {
                        latitude: travel1.destination.geopoint.latitude,
                        longitude: travel1.destination.geopoint.longitude,
                      },
                      {
                        latitude: travel2.origin.geopoint.latitude,
                        longitude: travel2.origin.geopoint.longitude,
                      },
                    ]);
                  } else if (
                    durationMaxP2 >= durationO2D2 &&
                    durationMaxP1 >= durationO1O2D2D1
                  ) {
                    console.log(
                      durationMaxP2 >= durationO2D2,
                      durationMaxP1 >= durationO1O2D2D1
                    );

                    key = key + 1;
                    console.log(key);
                    conbination.push([
                      key,
                      travel1,
                      travel2,
                      "D2",
                      `${travel1.destination.geopoint.latitude}, ${travel1.destination.geopoint.longitude}/${travel2.destination.geopoint.latitude}, ${travel2.destination.geopoint.longitude}/${travel2.origin.geopoint.latitude}, ${travel2.origin.geopoint.longitude}/${travel1.origin.geopoint.latitude}, ${travel1.origin.geopoint.longitude}`,
                      durationO1O2D2D1.toString(),
                      {
                        latitude: travel1.destination.geopoint.latitude,
                        longitude: travel1.destination.geopoint.longitude,
                      },
                      {
                        latitude: travel1.origin.geopoint.latitude,
                        longitude: travel1.origin.geopoint.longitude,
                      },
                    ]);
                  }
                }
              }
            }
          }
        })
      );
      console.log(conbination, "com");
      setListOfCombinable(conbination);
    }
    setLoading(false);
  };

  console.log(listOfCombinable, "list");
  const schedule = async (
    transfer1: string,
    transfer2: string,
    uid: string,
    pool: boolean,
    double: boolean,
    duration: string,
    geoOrigin: { latitude: number; longitude: number },
    geoDestination: { latitude: number; longitude: number }
  ) => {
    //El transfer1 es el buscando conductor y el 2 el agendado
    const d = {
      transfer1,
      transfer2,
      uid,
      pool,
      double,
      duration,
      geoOrigin,
      geoDestination,
    };
    console.log(d, "d");

    await CALLABLE_FUNCTIONS.ADMIN_SCHEDULE_POOL(d);
    setLoading(!loading);
  };
  useEffect(() => {
    zone();
  }, [loading]);

  useEffect(() => setZona(parsed.zona ? parsed.zona.toString() : ""));

  return (
    <Padding pad="5px">
      <Row>
        <H2>Traslados combinables</H2>
      </Row>
      <Row>
        <Col xl={2}>
          <Form.Group>
            <Form.Label>Desde</Form.Label>
            <DatePickerInput value={startDate} onChange={setStartDate} />
          </Form.Group>
        </Col>
        <Col xl={2}>
          <Form.Group>
            <Form.Label>Zona</Form.Label>
            <Form.Control
              as="select"
              value={zona.toString()}
              onChange={(e) => {
                history.push(
                  `?zona=${(e.target as HTMLTextAreaElement).value}`
                );
              }}
            >
              <option value={"TODAS LAS ZONAS"}>Todas las ZONAS</option>
              {zones.map((zona, index) => (
                <option key={index} value={zona.name}>
                  {zona.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Button
            style={{ marginTop: 30 }}
            onClick={() => inicialSet(startDate, zona)}
          >
            BUSCAR
          </Button>
        </Col>
      </Row>
      <Table hover responsive>
        <thead>
          <Tr>
            <th>Siniestro-Aut/NPA</th>
            <th>Estado</th>
            <th>Conductor</th>
            <th>Fecha Hora </th>
            <th>Paciente</th>
            <th>Origen 1</th>
            <th>Destino 1</th>
            <th></th>
            <th>Siniestro-Aut/NPA</th>
            <th>Estado</th>
            <th>Conductor</th>
            <th>Fecha Hora </th>
            <th>Paciente</th>
            <th>Origen 2</th>
            <th>Destino 2</th>
          </Tr>
        </thead>
        <tbody>
          {loading
            ? ""
            : listOfCombinable.map((items) => {
                console.log(items, items[0]);
                {
                  return (
                    <tr key={items[0].id}>
                      <Link to={viewTransfer(items[1].id)}>
                        {items[1].tracking ? (
                          <Td
                            style={{
                              wordBreak: "break-all",
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            {items[1].art.accident && items[1].art.authorization
                              ? items[1].art.accident +
                                "-" +
                                items[1].art.authorization
                              : items[1].art.accident
                              ? items[1].art.accident
                              : "NPA: " + items[1].art.npa}
                          </Td>
                        ) : (
                          <Td style={{ wordBreak: "break-all" }}>
                            {items[1].art.accident && items[1].art.authorization
                              ? items[1].art.accident +
                                "-" +
                                items[1].art.authorization
                              : items[1].art.accident
                              ? items[1].art.accident
                              : "NPA: " + items[1].art.npa}
                          </Td>
                        )}
                      </Link>

                      <Td>
                        {(items[1].status.stage ===
                          TransferStage.searching_driver ||
                          items[1].status.stage ===
                            TransferStage.searching_driver_return) &&
                        items[1].searching_cabify ? (
                          <b style={{ color: "#0000ff" }}>Buscando Cabify</b>
                        ) : items[1].status.stage === TransferStage.scheduled &&
                          items[1].searching_cabify ? (
                          <b style={{ color: "#0000ff" }}>Agendado</b>
                        ) : (
                          moduleTransferStage(items[1])
                        )}
                      </Td>
                      <Td>
                        {items[1].status.stage === 12 ? (
                          ""
                        ) : items[1].transfers ? (
                          <GetFullName item={items[1]} />
                        ) : (
                          ""
                        )}
                      </Td>
                      <Td>
                        <Moment
                          date={items[1].origin.time.toDate()}
                          format="DD/MM/YYYY"
                        />{" "}
                        {"\n"}
                        <Moment
                          date={items[1].origin.time.toDate()}
                          format="HH:mm"
                        />
                        {"-"}
                        <Moment
                          style={{ fontWeight: "bold" }}
                          date={items[1].destination.time.toDate()}
                          format="HH:mm"
                        />
                      </Td>
                      <Td>{items[1].passenger.name}</Td>
                      <Td>{getFullAddress(items[1].origin)}</Td>
                      <Td>{getFullAddress(items[1].destination)}</Td>
                      <Td>
                        <div>
                          {items[1].status.stage === TransferStage.scheduled ||
                          items[2].status.stage === TransferStage.scheduled ? (
                            <AsyncButton
                              onClick={async () => {
                                await schedule(
                                  items[1].transfers.searching_driver !==
                                    undefined
                                    ? items[1].transfers.searching_driver.id
                                    : items[2].transfers.searching_driver!.id,
                                  items[1].transfers.searching_driver ===
                                    undefined
                                    ? items[1].transfers.active!.id
                                    : items[2].transfers.active!.id,
                                  items[1].transfers.active! !== undefined
                                    ? items[1].transfers.active!.parent.parent!
                                        .id
                                    : items[2].transfers.active!.parent.parent!
                                        .id,
                                  true,
                                  false,
                                  items[5],
                                  items[6],
                                  items[7]
                                );
                              }}
                              variant="success"
                            >
                              Agendar
                            </AsyncButton>
                          ) : items[1].status.stage ===
                              TransferStage.searching_driver &&
                            items[2].status.stage ===
                              TransferStage.searching_driver ? (
                            <Button
                              className="pull-right"
                              style={{ margin: "0 10px 5px" }}
                              onClick={() => {
                                setTransfers1(
                                  items[1].transfers.searching_driver !==
                                    undefined
                                    ? items[1].transfers.searching_driver.id
                                    : ""
                                );
                                setTransfers2(
                                  items[2].transfers.searching_driver !==
                                    undefined
                                    ? items[2].transfers.searching_driver.id
                                    : ""
                                );
                                setDuration(items[5]);
                                setOrigin(items[6]);
                                setDestination(items[7]);
                                setShowScheduleModal(true);
                              }}
                            >
                              Agendar
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                        <div
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {" "}
                          <a
                            target="_blank"
                            href={ROUTES.GOOGLE_MAPS_POOL(items[4])}
                          >
                            {items[3]}
                          </a>
                        </div>
                      </Td>
                      <Link to={viewTransfer(items[2].id)}>
                        {items[2].tracking ? (
                          <Td
                            style={{
                              wordBreak: "break-all",
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            {items[2].art.accident && items[1].art.authorization
                              ? items[2].art.accident +
                                "-" +
                                items[2].art.authorization
                              : items[2].art.accident
                              ? items[2].art.accident
                              : "NPA: " + items[1].art.npa}
                          </Td>
                        ) : (
                          <Td style={{ wordBreak: "break-all" }}>
                            {items[2].art.accident && items[1].art.authorization
                              ? items[2].art.accident +
                                "-" +
                                items[2].art.authorization
                              : items[2].art.accident
                              ? items[2].art.accident
                              : "NPA: " + items[2].art.npa}
                          </Td>
                        )}
                      </Link>

                      <Td>
                        {(items[2].status.stage ===
                          TransferStage.searching_driver ||
                          items[2].status.stage ===
                            TransferStage.searching_driver_return) &&
                        items[1].searching_cabify ? (
                          <b style={{ color: "#0000ff" }}>Buscando Cabify</b>
                        ) : items[2].status.stage === TransferStage.scheduled &&
                          items[2].searching_cabify ? (
                          <b style={{ color: "#0000ff" }}>Agendado</b>
                        ) : (
                          moduleTransferStage(items[2])
                        )}
                      </Td>
                      <Td>
                        {items[2].status.stage === 12 ? (
                          ""
                        ) : items[2].transfers ? (
                          <GetFullName item={items[2]} />
                        ) : (
                          ""
                        )}
                      </Td>
                      <Td>
                        <Moment
                          date={items[2].origin.time.toDate()}
                          format="DD/MM/YYYY"
                        />{" "}
                        {"\n"}
                        <Moment
                          date={items[2].origin.time.toDate()}
                          format="HH:mm"
                        />
                        {"-"}
                        <Moment
                          style={{ fontWeight: "bold" }}
                          date={items[2].destination.time.toDate()}
                          format="HH:mm"
                        />
                      </Td>
                      <Td>{items[2].passenger.name}</Td>
                      <Td>{getFullAddress(items[2].origin)}</Td>
                      <Td>{getFullAddress(items[2].destination)}</Td>
                    </tr>
                  );
                }
              })}
        </tbody>
        <ScheduleMoldal
          transfer1={transfers1}
          transfer2={transfers2}
          duration={duration}
          geoOrigin={origin}
          geoDestination={destination}
          show={showScheduleModal}
          handleClose={() => setShowScheduleModal(false)}
        />
      </Table>
    </Padding>
  );
};

export const ScheduleMoldal = ({
  transfer1,
  transfer2,
  duration,
  geoOrigin,
  geoDestination,
  show,
  handleClose,
}: {
  transfer1: string;
  transfer2: string;
  duration: string;
  geoOrigin?: { latitude: number; longitude: number };
  geoDestination?: { latitude: number; longitude: number };
  show: boolean;
  handleClose: any;
}) => {
  const [uid, setDriver] = useState<string>("");
  const pool: boolean = true;
  const double: boolean = true;
  const [func, loading, error] = useAsyncFunction(
    () =>
      CALLABLE_FUNCTIONS.ADMIN_SCHEDULE_POOL({
        transfer1,
        transfer2,
        uid,
        pool,
        double,
        duration,
        geoOrigin,
        geoDestination,
      }),
    undefined,
    handleClose
  );
  console.log(uid, "driver");

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Agendar Traslado Combinado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner />
        ) : error ? (
          error
        ) : (
          <>
            <h6>Elija una acción</h6>
            <SelectDriverInput onChange={setDriver} value={uid} />
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonWithTooltip
          disabled={uid ? false : true}
          variant="warning"
          style={{ textAlign: "center" }}
          textTooltip="Se le agendan ambos traslados al mismo conductor"
          onClick={() => func()}
        >
          Agendar
        </ButtonWithTooltip>

        <Button
          variant="secondary"
          style={{ textAlign: "center", width: "4rem" }}
          onClick={handleClose}
        >
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
const Tr = styled.tr`
  th {
    text-align: center;
    vertical-align: middle !important;
  }
`;
const Td = styled.td`
  height: 10px;
`;
const Link = styled(R.Link)`
  &:hover {
    text-decoration: none;
    color: black;
  }
`;

export default AdminTransfersPool;
