import { UserType } from "controllers/user";
import { FbGeoPoint } from "types/transfer";

const FUNCTIONS =
  process.env.NODE_ENV === "development"
    ? "http://localhost:5001/satapp-27658/us-central1/api"
    : "https://southamerica-east1-satapp-27658.cloudfunctions.net/api";

const ROUTES = {
  LANDING: "/landing",
  SIGN_UP: "/sign-up",
  SIGN_IN: "/sign-in",
  SIGN_OUT: "/sign-out",
  RESET_PASSWORD: "/sign-in/resetpassword",
  SEND_INSTRUCTIONS: "/sign-in/sendinstructions",
  ACCOUNT: "/cuenta",
  PASSWORD_FORGET: "/pw-forget",
  VERIFICATION_SUCCESS: "/verification-success",

  MICROLANDING: (idUser?: string, idTransfer?: string) =>
    `/microlanding/${idUser || ":idUser"}/${idTransfer || ":idTransfer"}`,
  ASK_RETURN_WITHOUT_WAIT: (idModule: string | undefined = undefined) =>
    `/pedir-vuelta/${idModule || ":idModule"}`,
  ASK_RETURN_WITH_WAIT: (idModule?: string) =>
    `/pedir-vuelta-espera/${idModule || ":idModule"}`,
  LATE_TRANSFER_ART: (idModule?: string) =>
    `/pedido-tarde-art/${idModule || ":idModule"}`,
  CANCEL_CABIFY: (idModule?: string) =>
    `/cancelar-cabify/${idModule || ":idModule"}`,
  CHANGE_CABIFY: (idModule?: string) =>
    `/cambiar-cabify/${idModule || ":idModule"}`,

  HOME: (userType: UserType | undefined) =>
    userType === UserType.regular
      ? ROUTES.USER_HOME
      : userType === UserType.admin
      ? ROUTES.ADMIN_HOME
      : userType === UserType.art
      ? ROUTES.ART_HOME
      : ROUTES.USER_HOME,

  ADMIN: "/admin",
  ADMIN_HOME: "admin/traslados",
  ADMIN_HOME_PROFILES: "/admin/inicio/usuarios",
  ADMIN_HOME_PROFILES_PENDING: "/admin/inicio/perfiles-a-revisar",
  ADMIN_HOME_PROFILES_REVIEWED: "/admin/inicio/perfiles-revisados",
  ADMIN_HOME_PROFILES_PASSED: "/admin/inicio/perfiles-aprobados",
  ADMIN_HOME_PROFILES_REJECTED: "/admin/inicio/perfiles-rechazados",
  //ADMIN_HOME_CARS: "/admin/inicio/autos",
  //ADMIN_HOME_LICENCES: "/admin/inicio/licencias",
  ADMIN_CAR_VIEW: (idCar?: string, uidUser?: string) =>
    `/admin/autos/${idCar || ":idCar"}/${uidUser || ":uidUser"}`,
  ADMIN_USER_PROFILE_VIEW: (uid?: string) =>
    `/admin/perfiles/${uid || ":uidUser"}`,
  //ADMIN_HOME_CONTRACTS: "/admin/inicio/carta-oferta",
  //ADMIN_HOME_PENDING_BANK_REVIEWS: "/admin/inicio/datos-afip-banco",
  //ADMIN_HOME_PENDING_USERS: "/admin/inicio/usuariosincompletos",
  ADMIN_REVIEW_PROFILE: (uidUser?: string) =>
    `/admin/inicio/perfiles/revisar/${uidUser || ":uidUser"}`,
  ADMIN_REVIEW_LICENCE: (uidUser?: string) =>
    `/admin/inicio/licencias/revisar/${uidUser || ":uidUser"}`,
  ADMIN_REVIEW_BANKDATA: (uidUser?: string) =>
    `/admin/inicio/impositivo/revisar/${uidUser || ":uidUser"}`,

  ADMIN_CONFIGURATION: "/admin/configuracion",
  ADMIN_CONFIGURATION_IIBB: "/admin/configuracion?t=iibb",
  ADMIN_CONFIGURATION_MAXPRICE_TRANSFER_ART: "/admin/configuracion/maxprecio",
  ADMIN_CONFIGURATION_NEW_ZONE: `/admin/configuracion/zona/nueva`,
  ADMIN_CONFIGURATION_NEW_PROVINCE: `/admin/configuracion/provincia/nueva`,
  ADMIN_CONFIGURATION_NEW_LOCALITY: `/admin/configuracion/localidad/nueva`,
  ADMIN_CONFIGURATION_NEW_ZONE_SALE: `/admin/configuracion/zona_venta/nueva`,
  ADMIN_CONFIGURATION_VIEW_ZONE: (id: string | undefined = undefined) =>
    `/admin/configuracion/zona/ver/${id ? id : ":id"}`,
  ADMIN_CONFIGURATION_VIEW_LOCALITY: (id: string | undefined = undefined) =>
    `/admin/configuracion/localidad/ver/${id ? id : ":id"}`,
  ADMIN_CONFIGURATION_VIEW_PROVINCE: (id: string | undefined = undefined) =>
    `/admin/configuracion/provincia/ver/${id ? id : ":id"}`,
  ADMIN_CONFIGURATION_VIEW_SALE_ZONE: (id: string | undefined = undefined) =>
    `/admin/configuracion/zona_venta/ver/${id ? id : ":id"}`,

  //ADMIN_STATIONS: "/admin/bases",
  ADMIN_CROSS: "/admin/cruces",
  ADMIN_LOCALITIES: "/admin/localidades",
  ADMIN_TOLLS: "/admin/peajes",
  ADMIN_VIEW_TOLLS: (idTransfer?: string, uidUserTransfer?: string) =>
    `/admin/peajes/ver/${idTransfer || ":idTransfer"}/${
      uidUserTransfer || ":uidUser"
    }`,
  ADMIN_MODULES_TRANSFERS: "/admin/traslados",
  ADMIN_MODULES_TRANSFERS_CONDUCTOR: "/admin/traslados_por_conductor",
  ADMIN_MODULES_TRANSFERS_POOL: "/admin/traslados_combinables",
  ADMIN_MODULES_TRANSFERS_MISSING_PDF: "/admin/traslados_sin_pdf",
  ADMIN_MODULES_TRANSFERS_WRONG: "/admin/traslados_errores",
  ADMIN_MODULES_TRANSFERS_WRONG_ID: "/admin/traslados_errores_id",
  ADMIN_MODULES_TRANSFERS_WRONG_CROSS: "/admin/traslados_con_demora",
  ADMIN_MODULES_TRANSFERS_WRONG_AMOUNT: "/admin/traslados_errores_monto",
  ADMIN_MODULES_TRANSFERS_WRONG_AMOUNT_KM: "/admin/traslados_errores_monto_km",
  ADMIN_MODULES_TRANSFERS_WRONG_REJECTED: "/admin/traslados_errores_rechazada",
  ADMIN_MODULES_TRANSFERS_WRONG_MISSING_INFO: "/admin/traslados_incompletos",
  ADMIN_MODULES_TRANSFERS_WRONG_MISSING_BILL_INFO:
    "/admin/traslados_sin_facturar_comprados",
  ADMIN_MODULES_TRANSFERS_SATAPP_INTERNO: "/admin/traslados_satapp_interno",
  //ADMIN_DUPLICATED_BILLS: "/admin/facturas_duplicadas",
  ADMIN_MODULE_TRANSFER_VIEW: (idModule?: string) =>
    `/admin/traslados/${idModule || ":idModule"}`,
  ADMIN_MODULE_TRANSFER_WRONG_VIEW: (idModule?: string) =>
    `/admin/traslados_errores/${idModule || ":idModule"}`,
  ADMIN_MODULE_FIX_DIRECTION: (idModule?: string) =>
    `/admin/corregir_destino/${idModule || ":idModule"}`,
  ADMIN_MODULE_CONFUSING_ADDRESS: (idModule?: string) =>
    `/admin/direccion_confusa/${idModule || ":idModule"}`,
  ADMIN_NEW_TRANSFER: "/admin/nuevo-traslado",
  ADMIN_NEW_TRANSFER_EXCEL: "/admin/nuevo-traslado-excel",
  ADMIN_CONTRACT_VIEW: (id?: string, uid?: string) =>
    `/admin/carta-oferta/${id || ":idContract"}/${uid || ":uidUser"}`,

  ADMIN_ART_PROFILE_VIEW: (uid?: string) =>
    `/admin/perfiles-art/${uid || ":uid"}`,

  ADMIN_SALES: "/admin/ventas",
  ADMIN_SALES_PURCHASES: "/admin/ventas/compras",
  ADMIN_SALES_PURCHASES_GENERATE_BILL: "/admin/ventas/compras/facturar",
  ADMIN_SALES_SALES: "/admin/ventas/ventas",
  ADMIN_SALES_SALES_GENERATE_BILL: "/admin/ventas/ventas/facturar",
  ADMIN_SALES_SALES_IIBB: "/admin/ventas/ventas/iibb",
  ADMIN_SALES_SALES_ART_BILL_INFO: (id?: string) =>
    `/admin/ventas/ventas/facturas/${id || ":id"}`,
  ADMIN_SALES_SALES_DRIVER_BILL_INFO: (id?: string) =>
    `/admin/ventas/compras/facturas/${id || ":id"}`,
  ADMIN_SALES_DRIVERS_PAYMENTS: "/admin/ventas/pagos",
  ADMIN_SALES_DRIVER_PAYMENT: (id?: string) =>
    `/admin/ventas/pagos/${id || ":id"}`,
  ADMIN_SALES_DRIVERS_PAYMENTS_GENERATE_PAYMENT: "/admin/ventas/pagos/generar",
  ADMIN_SALES_CHARGE: "/admin/ventas/cobranza",
  ADMIN_SALES_CHARGE_ADD: "/admin/ventas/cobranza/generar",
  ADMIN_SALES_ART_PAYMENT: (id?: string) =>
    `/admin/ventas/cobranza/${id || ":id"}`,
  ADMIN_SALES_FINANCES: "/admin/ventas/finanzas",
  ADMIN_SALES_MANAGEMENT: "/admin/ventas/gestion",
  ADMIN_SALES_BAS: "/admin/ventas/bas",

  USER: "/user",
  USER_HOME: "/user/inicio",
  USER_MY_PROFILE: "/user/mi-perfil",
  USER_STATIONS: "/user/bases",
  USER_CARS: "/user/autos",
  USER_CARS_VIEW: (id?: string) => `/user/autos/${id || ":idCar"}`,
  USER_CARS_ADD_OR_EDIT: (idCar?: string) =>
    `/user/autos/nuevo/${idCar || ":idCar"}`,
  USER_CONTRACTS: "/user/carta-oferta",
  USER_PAYMENTS: "/user/pagos",
  USER_SCHEDULE_TRANFSERS: "/user/disponibles",
  USER_SCHEDULE_TRANFSERS_POOL: "/user/disponibles_pool",
  USER_SCHEDUL_TRANFSERS_POOL: "/user/agenda_pool",
  USER_PRIORITY_TRANFSERS: "/user/retornos",
  USER_PRIORITY: "/user/retornos-agendados",
  USER_ACTIVE: "/user/active",
  USER_CONTRACTS_VIEW: (id?: string) =>
    `/user/carta-oferta/${id || ":idContract"}`,
  USER_INVITES: "/user/invitaciones",
  USER_PROFILE_HISTORY: "/user/logs",
  USER_NEWCOMERS: "/user/bienvenido-form",

  ART: "/art",
  ART_HOME: "/art/inicio",
  ART_REPORTS: "/art/informes",
  ART_PURCHASES: "/art/compras",
  ART_PURCHASES_BILL: (id?: string) => `/art/compras/${id || ":id"}`,
  ART_TRANSFER_VIEW: (idModule?: string) =>
    `/art/traslados/${idModule || ":idModule"}`,
  ART_MODULE_FIX_DIRECTION: (idModule?: string) =>
    `/art/corregir_destino/${idModule || ":idModule"}`,
  ART_MODULE_CONFUSING_ADDRESS: (idModule?: string) =>
    `/art/direccion_confusa/${idModule || ":idModule"}`,
  API: "/api",
  API_USER: `${FUNCTIONS}/user`,
  API_TRANSFERS: `${FUNCTIONS}/transfer`,
  API_MAP_LOCATION: `${FUNCTIONS}/map/location`,
  API_MAP_COMPLETE_ADDRESS: `${FUNCTIONS}/map/complete-address`,
  API_MAP_ADDRESS_FROM_LATLNG: `${FUNCTIONS}/map/address-from-latlng`,

  FAQ: "/faq",
  PRIVACY: "/privacidad",
  TERMS_AND_CONDITIONS: "/terminos/particular",
  PLAYSTORE: "https://play.google.com/store/apps/details?id=com.satapp.app",
  GOOGLE_MAPS_SEARCH_COORDINATES: (p: FbGeoPoint) =>
    `https://www.google.com/maps/search/?api=1&query=${p.latitude},${p.longitude}`,
  GOOGLE_MAPS_SEARCH_DIRECTIONS: (p?: string) =>
    `https://www.google.com/maps/search/?api=1&query=${p}`,
  GOOGLE_MAPS_POOL: (p: string) => `https://www.google.com/maps/dir/${p}`,
};

export default ROUTES;
