import { useSelector } from "react-redux";
import { uidSelector } from "redux/selectors";
import { useState, useEffect } from "react";
import moment from "moment";
import { FIRESTORE } from "constants/firestore";
import { FbDate } from "types/transfer";
import { Row, Col, Form, Button } from "react-bootstrap";
import React from "react";
import { H2, NoData, Spinner } from "components/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRecycle } from "@fortawesome/free-solid-svg-icons";
import ModuleRequestCard from "./moduleRequestCard";

const UserSchedulePriorityTransfers = () => {
  const uid = useSelector(uidSelector);

  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [selectedZone, setSelectedZone] = useState<string>("Todas");
  const today = new Date();
  console.log(today);

  //TODO CAMBIAR EL SISTEMA DE ZONAS PARA QUE NO ESTE HARDCODEADO
  const zones = [
    "CABA",
    "LA PLATA",
    "GBA NORTE",
    "GBA SUR",
    "GBA OESTE",
    "Zona Norte Plus",
  ];
  //TODO CAMBIAR EL SISTEMA DE ZONAS PARA QUE NO ESTE HARDCODEADO

  const setTransfers = () => {
    setLoading(true);
    FIRESTORE.COLLECTION_MODULES_TRANSFER()
      .where(
        "destination.time",
        ">=",
        FbDate.fromDate(moment().startOf("day").toDate())
      )
      .where(
        "destination.time",
        "<",
        FbDate.fromDate(moment(moment()).startOf("day").add(1, "day").toDate())
      )
      .orderBy("destination.time")
      .get()
      .then((data) =>
        data.docs
          .map((d) => ({ data: d.data(), id: d.id }))
          .filter(
            (module) =>
              (module.data.art.id === "MekXauxMVPd1ayCGpIwWjg7j6Z73" ||
                module.data.art.id === "GjP1gJT9T1eK0FGe2kfx6o4T80J2") &&
              module.data.priority === undefined &&
              module.data.status.stage === 4 &&
              module.data.status.doing_return === true &&
              module.data.type !== 1
          )
          .filter((module) => {
            if (
              selectedZone !== "Todas" &&
              module.data.info.zona !== selectedZone
            )
              return false;
            else return true;
          })
      )
      .then((filtered) => setItems(filtered))
      .then(() => setLoading(false));
  };
  console.log(items, items.length, "item");

  useEffect(() => {
    const interval = setInterval(() => {
      setTransfers();
    }, 300000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setTransfers();
  }, [selectedZone]);

  return (
    <div style={{ maxWidth: "100%" }}>
      <Row>
        <Col xs={12}>
          <H2>TRASLADOS DEL DÍA SIN PRIORIDAD</H2>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Form.Label>Buscar por zona</Form.Label>
          <Form.Control
            as="select"
            value={selectedZone}
            onChange={(e) => {
              setSelectedZone(e.target.value);
            }}
          >
            <option value="Todas">TODAS LAS ZONAS</option>
            {zones.map((z) => (
              <option key={z} value={z}>
                {z}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      {loading ? (
        <Spinner style={{ margin: 20 }} />
      ) : items.length === 0 ? (
        <NoData />
      ) : (
        items.map((modul) => (
          <div key={modul.id}>
            <ModuleRequestCard
              data={modul.data}
              id={modul.id}
              reload={setTransfers}
            />
          </div>
        ))
      )}
      <div
        style={{
          position: "fixed",
          bottom: "3%",
          right: "3%",
        }}
      >
        <Button>
          <FontAwesomeIcon icon={faRecycle} onClick={setTransfers} />
        </Button>
      </div>
    </div>
  );
};

export default UserSchedulePriorityTransfers;
