import { getStorageFileUrl } from "components/Primitives/File";
import FbImage from "components/Primitives/Image/with-modal";
import {
  Button,
  Center,
  H3,
  Padding,
  RibbonContainer,
  RibbonTopLeft,
  Spinner,
  AsyncButton,
} from "components/styles";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import {
  useDatabaseDocref,
  useDbCollection,
  useDbDocument,
} from "controllers/hooks";
import { getPositionUserPath } from "controllers/user";
import { getPersonalInformationColDoc } from "controllers/user/info/personal";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner as BSpinner } from "react-bootstrap";
import { isLoaded } from "react-redux-firebase";
import { useProfile } from "redux/app";
import styled from "styled-components";
import {
  FbDocument,
  ScheduledTransfer,
  Toll,
  TransferStage,
} from "types/transfer";
import {
  GpsStatus,
  PersonalInformation,
  RevisionStatus,
  UserType,
} from "types/user-data";
import { DataTransfer, PassengerData } from "../unassigned";
import { Header } from "./header";
import { MapDriver } from "./map";
import { ReportSection } from "./report";

export const ScheduledTransferView = ({
  refTransfer,
  idModule,
}: {
  refTransfer: FbDocument<ScheduledTransfer>;
  idModule?: string;
}) => {
  const uidDriver = refTransfer.parent.parent!.id;
  const isAdmin = useProfile().type === UserType.admin;
  const transfer = useDatabaseDocref<ScheduledTransfer>(refTransfer);
  const driverData = useDbDocument<PersonalInformation>(
    ...getPersonalInformationColDoc(uidDriver)
  );
  const felevalStatus =
    refTransfer.parent.parent!.id === "GjP1gJT9T1eK0FGe2kfx6o4T80J2";
  const defineTelephone = () => {
    if (transfer) {
      if (typeof transfer!.passenger.tel === "string") {
        return transfer!.passenger.tel;
      } else {
        for (let i = 0; i < transfer!.passenger.tel.length; i++) {
          if (
            transfer!.passenger.tel[i].number != "" &&
            transfer!.passenger.tel[i].number != "-"
          ) {
            const phone = transfer.passenger.tel[i].number.replace("-", "");
            return phone;
          }
        }
      }
    }
    return "";
  };
  let telephone = defineTelephone();
  const forceSMS = async () => {
    CALLABLE_FUNCTIONS.ADMIN_FORCE_SMS(refTransfer.id, uidDriver);
  };

  if (!transfer || !driverData) {
    if (
      transfer?.status.stage != TransferStage.recieved_by_feleval &&
      transfer?.status.stage != TransferStage.ended_by_feleval &&
      transfer?.status.stage != TransferStage.tolls_and_vouchers_recieved &&
      transfer?.status.stage != TransferStage.bill_data_recieved &&
      transfer?.status.stage != TransferStage.finished
    ) {
      return <>Error</>;
    }
  }

  return (
    <Padding pad="5px">
      <Header
        transfer={transfer}
        refTransfer={refTransfer}
        uidDriver={uidDriver}
        idModule={idModule}
      />

      {transfer.status.active_report && (
        <ReportSection
          refTransfer={refTransfer}
          transfer={transfer}
          uidDriver={uidDriver}
        />
      )}

      <Row>
        <Col>
          <PassengerData passenger={transfer.passenger} tel={telephone} />
        </Col>
        {driverData ? (
          driverData.name == "Cabify" || driverData.surname == "Villasuso" ? (
            <Col>
              <Row>
                <Title>Datos del Chofer</Title>
              </Row>
              <InfoRow
                label="Nombre"
                info={driverData.name + " " + driverData.surname}
              />
              <InfoRow
                label="Teléfono"
                info={
                  driverData.phone.area_code + " " + driverData.phone.number
                }
              />
              {transfer.link_cabify ? (
                <>
                  <Button
                    className="pull-right"
                    style={{ margin: "0 10px 5px", textAlign: "center" }}
                    onClick={() => window.open(transfer.link_cabify, "_blank")}
                  >
                    Ver en Cabify
                  </Button>
                  <Button
                    className="pull-right"
                    style={{ margin: "0 10px 5px", textAlign: "center" }}
                    onClick={() => forceSMS()}
                  >
                    Enviar SMS
                  </Button>
                </>
              ) : null}
            </Col>
          ) : (
            <Col>
              <Row>
                <Title>Datos del Chofer</Title>
              </Row>
              <InfoRow
                label="Nombre"
                info={driverData.name + " " + driverData.surname}
              />
              <InfoRow
                label="Teléfono"
                info={
                  driverData.phone.area_code + " " + driverData.phone.number
                }
              />
            </Col>
          )
        ) : (
          ""
        )}
        {felevalStatus && (
          <Col>
            <Row>
              <Title>Datos del Chofer</Title>
            </Row>
            <InfoRow label="Nombre" info={"Feleval SRL"} />
          </Col>
        )}
        {transfer.car && (
          <Col>
            <Row>
              <Title>Datos del auto</Title>
            </Row>
            <InfoRow
              label="Descripción"
              info={[
                transfer.car.brand,
                transfer.car.model,
                transfer.car.year,
                transfer.car.color,
              ].join(" ")}
            />
            <InfoRow label="Dominio" info={transfer.car.licence} />
          </Col>
        )}
      </Row>

      <DataTransfer id={refTransfer.id} transfer={transfer} />

      {/* {transfer.tolls !== undefined && (
        <>
          <Row>
            <Title>Peajes</Title>
          </Row>
          <Row style={{ padding: 20 }}>
            {transfer.tolls.data.map((t, index) => (
              <TollCard key={index} t={t} />
            ))}
          </Row>
        </>
      )} */}

      <MapDriver
        uid={uidDriver}
        transfer={transfer}
        origin={transfer.origin.geopoint}
        destination={transfer.destination.geopoint}
        backtrack={transfer.backtrack?.geopoint}
      />

      <Row>
        {(transfer.status.scanned?.going?.signature !== undefined ||
          transfer.status.scanned?.return?.signature !== undefined) && (
          <Col>
            <Row>
              <Title>Firma</Title>
            </Row>
            <Row>
              {transfer.status.scanned?.going?.signature !== undefined && (
                <SignatureCard
                  photo={transfer.status.scanned.going.signature}
                  title="Firma"
                />
              )}
              {transfer.status.scanned?.return?.signature !== undefined && (
                <SignatureCard
                  photo={transfer.status.scanned.return.signature}
                  title="Firma"
                />
              )}
            </Row>
          </Col>
        )}
        <Col>
          <Row>
            <Title>Escáner DNI</Title>
          </Row>
          {transfer.status.scanned?.going?.identification !== undefined && (
            <InfoRow
              label="DNI"
              info={
                transfer.status.scanned?.going?.identification !== undefined
                  ? transfer.status.scanned.going.identification
                  : "SIN DNI"
              }
            />
          )}

          {/*  <InfoRow
            label="DNI"
            info={
              transfer.status.scanned?.return?.identification !== undefined
                ? transfer.status.scanned.return.identification
                : ""
            }
          /> */}
        </Col>
      </Row>

      <div style={{ margin: "100px" }}></div>
    </Padding>
  );
};

export const VoucherButton = ({
  src,
  text,
}: {
  src: string;
  text?: string;
}) => {
  const [link, setLink] = useState<string | undefined>(undefined);
  useEffect(() => {
    getStorageFileUrl(src).then(setLink);
  }, [src]);
  if (!link) return <BSpinner animation="border" size="sm" />;
  return (
    <a className="pull-right" href={link} target="_blank">
      <Button style={{ margin: "0 10px 5px" }} variant="info">
        {text || "Ver Voucher"}
      </Button>
    </a>
  );
};

export const InfoRow = ({
  label,
  info,
}: {
  label: string | JSX.Element | undefined;
  info: string | JSX.Element | undefined | number;
}) => (
  <Row>
    <Col md={4} style={{ color: "#888" }}>
      {label}
    </Col>
    <Col style={{ color: "#333" }}>{info}</Col>
  </Row>
);

export const Title = styled.h3`
  margin: 10px 15px;
  padding: 10px 5px;
  background-color: #f1f1f1;
  font-size: 1.17em;
  font-weight: 700;
  width: 100%;
  border-radius: 5px;
  -webkit-box-shadow: 0px 1px 48px -22px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 48px -22px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 1px 48px -22px rgba(0, 0, 0, 0.75);
`;

export const TollCard = ({ t }: { t: Toll }) => (
  <Col xl={4}>
    <RibbonContainer>
      {t.status === RevisionStatus.Aprobado ? (
        <RibbonTopLeft color="#00426A">
          <span>APROBADO</span>
        </RibbonTopLeft>
      ) : t.status === RevisionStatus.Desaprobado &&
        t.amount_corrected !== undefined ? (
        <RibbonTopLeft color="#00426A">
          <span>CORREGIDO</span>
        </RibbonTopLeft>
      ) : t.status === RevisionStatus.Desaprobado ? (
        <RibbonTopLeft color="#00426A">
          <span>DESAPROBADO</span>
        </RibbonTopLeft>
      ) : t.status === RevisionStatus["No revisado"] ? (
        <RibbonTopLeft color="grey">
          <span>SIN REVISAR</span>
        </RibbonTopLeft>
      ) : (
        "Error"
      )}

      <Center>
        <FbImage src={t.photo} />
        {t.name && (
          <>
            <b>{t.name}</b>
            <br />
          </>
        )}
        <b>Precio ${t.amount}</b>
        <br />
        {t.amount_corrected && <b>Precio Corregido ${t.amount_corrected}</b>}
      </Center>
    </RibbonContainer>
  </Col>
);

const SignatureCard = ({ photo, title }: { photo: string; title: string }) => (
  <Col xl={6}>
    <RibbonContainer>
      <Center>
        <H3>{title}</H3>
        <FbImage src={photo} />
      </Center>
    </RibbonContainer>
  </Col>
);
