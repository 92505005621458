import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Padding,
  Spinner,
  Button,
  SubmitButton,
  TextWithTooltip,
} from "components/styles";
import { useDatabaseDocref } from "controllers/hooks";
import { getFullAddress } from "controllers/map";
import moment from "moment";
import { useState, useEffect } from "react";
import { Row, Alert, Col, Modal, Form, FormControl } from "react-bootstrap";
import Moment from "react-moment";
import { isLoaded } from "react-redux-firebase";
import {
  FbDocument,
  FinishStatus,
  Passenger,
  ScheduledTransfer,
  TransferInformation,
  TransferRequest,
  TransferStage,
  TransferStageDefinitions,
  TypeAmountTransfer,
  Tolls,
} from "types/transfer";
import { InfoRow, Title, TollCard } from "./scheduled";
import { ChallengeBilling } from "types/billing";
import { ChallengeType, User } from "types/user-data";
import { useProfile } from "redux/app";
import { UserType, getActiveUsers } from "controllers/user";
import ROUTES from "constants/routes";
import React from "react";
import { Field } from "components/input/field";
import { reduxForm, change } from "redux-form";
import { CALLABLE_FUNCTIONS } from "constants/cloud-functions";
import { SelectDriverInput } from "components/Admin/Sales/Purchases";
import { WithId } from "controllers/database";
import _ from "lodash";

export const UnassignedTransferView = ({
  refTransfer,
}: {
  refTransfer: FbDocument<TransferRequest>;
}) => {
  const idTransfer = refTransfer.id;
  const userType = useProfile().type;
  const [showCoordinatedWithDelayModal, setShowCoordinatedWithDelayModal] =
    useState(false);
  const transfer = useDatabaseDocref<TransferRequest>(refTransfer);
  const defineTelephone = () => {
    if (transfer) {
      if (typeof transfer!.passenger.tel === "string") {
        return transfer!.passenger.tel;
      } else {
        for (let i = 0; i < transfer!.passenger.tel.length; i++) {
          if (
            transfer!.passenger.tel[i].number != "" &&
            transfer!.passenger.tel[i].number != "-"
          ) {
            const phone = transfer.passenger.tel[i].number.replace("-", "");
            return phone;
          }
        }
      }
    }
    return "";
  };
  let telephone = defineTelephone();

  if (!isLoaded(transfer)) return <Spinner />;

  if (!transfer) return <>Error</>;

  return (
    <Padding pad="5px">
      <Row>
        <Col>
          {userType === UserType.admin &&
            !transfer.coordinated_schedule_admin &&
            new Date() >
              moment(transfer.origin.time.toDate())
                .subtract(2, "hours")
                .toDate() &&
            moment(transfer.origin.time.toDate()).toDate() > new Date() && (
              <Button
                className="pull-right"
                style={{ margin: "0 10px 5px" }}
                variant="danger"
                onClick={() => setShowCoordinatedWithDelayModal(true)}
              >
                Viaje Coordinado con Retraso
              </Button>
            )}
        </Col>
      </Row>
      <PassengerData passenger={transfer.passenger} tel={telephone} />
      <DataTransfer id={idTransfer} transfer={transfer} />

      <div style={{ margin: "100px" }}></div>
      <InfoMail
        show={showCoordinatedWithDelayModal}
        handleClose={() => setShowCoordinatedWithDelayModal(false)}
        transfer={transfer}
      />
    </Padding>
  );
};

export const PassengerData = ({
  passenger,
  tel,
}: {
  passenger: Passenger;
  tel?: string;
}) => (
  <>
    <Row>
      <Title>Datos del pasajero</Title>
    </Row>
    <InfoRow label="Nombre" info={passenger.name} />
    <InfoRow label="Documento" info={passenger.dni} />
    <InfoRow label="Teléfono" info={tel ? tel : "-"} />
  </>
);

export const DataTransfer = ({
  transfer,
  id,
}: {
  transfer: ScheduledTransfer | TransferRequest;
  id: string;
}) => {
  const isAdmin = useProfile().type === UserType.admin;
  return (
    <>
      <Row>
        <Title>Datos del traslado</Title>
      </Row>
      <Row>
        {"sale" in transfer &&
          (transfer.sale?.billed_from_driver === 2 ? (
            <Col sm="auto">
              <Alert variant="danger">NO FACTURABLE</Alert>
            </Col>
          ) : (
            ""
          ))}
        {"isLate" in transfer &&
          (transfer.isLate?.minutes && transfer.isLate.minutes > 0 ? (
            <Col sm="auto">
              <Alert variant="warning">
                {" "}
                {`Viaje con ${transfer.isLate.minutes} minutos de demora`}
              </Alert>
            </Col>
          ) : (
            ""
          ))}
        {"challenge" in transfer && (
          <Col sm="auto">
            <Alert variant="warning">
              {" "}
              {`Facturación desafío: ${
                ChallengeBilling[transfer.challenge!.to_be_billed]
              }`}
            </Alert>
          </Col>
        )}
        {"farFromOrigin" in transfer && (
          <Col sm="auto">
            <Alert variant="warning"> {"ALEJADO DEL ORIGEN/DESTINO"}</Alert>
          </Col>
        )}
        {transfer.isUrgent && (
          <Col sm="auto">
            <Alert variant="primary"> {"TRASLADO YA"}</Alert>
          </Col>
        )}{" "}
        {transfer.wheelchair === true && (
          <Col sm="auto">
            <Alert variant="warning"> {"TRANSPORTA SILLA DE RUEDAS"}</Alert>
          </Col>
        )}
        {"coordinated_schedule" in transfer && (
          <Col sm="auto">
            <Alert variant="dark"> {"Coordinó horario con paciente"}</Alert>
          </Col>
        )}
        {"coordinated_schedule_admin" in transfer && (
          <Col sm="auto">
            <Alert variant="dark">
              {" "}
              {`Admin Coordinó horario con ${
                transfer!.coordinated_schedule_admin?.minutes
              } min de demora`}
            </Alert>
          </Col>
        )}
      </Row>
      <InfoRow label="Id" info={id} />
      {"status" in transfer && (
        <InfoRow
          label="Estado"
          info={
            TransferStageDefinitions[transfer.status.stage] +
            (transfer.status.finished_status !== undefined
              ? ` (${FinishStatus[transfer.status.finished_status]})`
              : "")
          }
        />
      )}
      <InfoRow label="ART" info={transfer.art?.name} />
      <InfoRow
        label="Fecha y Hora estimada en origen"
        info={
          <Moment
            date={transfer.origin.time.toDate()}
            format="DD/MM/YYYY HH:mm"
          />
        }
      />
      <InfoRow
        label="Hora estimada en destino"
        info={
          <Moment date={transfer.destination.time.toDate()} format="HH:mm" />
        }
      />
      <InfoRow
        label={
          <a
            target="_blank"
            href={ROUTES.GOOGLE_MAPS_SEARCH_DIRECTIONS(
              `${transfer.origin.geopoint.latitude}, ${transfer.origin.geopoint.longitude}`
            )}
          >
            Dirección de origen
          </a>
        }
        info={getFullAddress(transfer.origin)}
      />
      <InfoRow
        label={
          <a
            target="_blank"
            href={ROUTES.GOOGLE_MAPS_SEARCH_DIRECTIONS(
              `${transfer.destination.geopoint.latitude}, ${transfer.destination.geopoint.longitude}`
            )}
          >
            Dirección de destino
          </a>
        }
        info={getFullAddress(transfer.destination)}
      />
      {transfer.backtrack && (
        <>
          <InfoRow
            label="Hora en Retorno"
            info={
              <Moment date={transfer.backtrack.time.toDate()} format="HH:mm" />
            }
          />
          <InfoRow
            label="Dirección de Retorno"
            info={getFullAddress(transfer.backtrack)}
          />
        </>
      )}
      <Row>
        <Title>Distancia, Tiempo y Precio</Title>
      </Row>
      <InfoRow
        label="Distancia origen-destino"
        info={`${transfer.info.distance_origin_destination}m`}
      />
      <InfoRow
        label="Tiempo estimado origen-destino"
        info={`${transfer.info.duration_origin_destination}min`}
      />
      {transfer.backtrack && (
        <InfoRow
          label="Tiempo estimado destino-retorno"
          info={`${transfer.info.duration_destination_backtrack}min`}
        />
      )}
      <InfoRow
        label="Precio"
        info={
          <>
            <AmountInfo
              amount={Math.trunc(transfer.amount)}
              info={transfer.info}
              finishedNegative={
                "status" in transfer &&
                transfer.status.finished_status === FinishStatus.Negativo
              }
            />
            <AmountInfoWait info={transfer} />
            <ExtraAdditionals
              info={transfer.info}
              challengeType={
                "challenge" in transfer && transfer.challenge?.type
              }
              tolls={"tolls" in transfer && transfer.tolls}
              base={transfer.amount}
            />
          </>
        }
      />
      {transfer.obs && <InfoRow label="Observación" info={transfer.obs} />}
      {isAdmin && transfer.obs_satapp && (
        <Row>
          <Title style={{ backgroundColor: "yellowgreen" }}>
            {transfer.obs_satapp}
          </Title>
        </Row>
      )}
      {"tolls" in transfer && (
        <>
          <Row>
            <Title>Peajes</Title>
          </Row>
          <Row style={{ padding: 20 }}>
            {transfer.tolls!.data.map((t, index) => (
              <TollCard key={index} t={t} />
            ))}
          </Row>
        </>
      )}
    </>
  );
};

const AmountInfo = ({
  amount,
  info,
  finishedNegative,
}: {
  amount: number;
  info: TransferInformation;
  finishedNegative: boolean;
}) => {
  const amountInfo = info.amount;
  if (!amountInfo) return <span>${amount}</span>;

  let text;
  if (finishedNegative)
    text = (
      <>
        Tiempo inicio-{">"}informe: {amountInfo.duration_start_to_report}min
        <br />
        Tiempo informe: {amountInfo.duration_report}min
        <br />
        Distancia inicio-{">"}origen: {amountInfo.distance_start_to_origin}m
        <br />
        Valor negativo real: $
        {amountInfo.real_price && Math.trunc(amountInfo.real_price)}
        <br />
        Valor negativo máximo: ${amountInfo.maximum_price_negative_transfer}
        <br />
        Valor negativo mínimo: ${amountInfo.minimum_price_negative_transfer}
        <br />
        Valor minuto espera: ${amountInfo.price_minute_wait}
        <br />
        Valor minuto viaje: ${amountInfo.price_minute_trip}
        <br />
        Valor km viaje corto: ${amountInfo.price_km_short_trip}
        <br />
      </>
    );
  else
    text = (
      <>
        {amountInfo.custom_zone !== undefined && (
          <>
            Provincia/Zona: amountInfo.custom_zone
            <br />
          </>
        )}
        Tipo:{" "}
        {amountInfo.type !== undefined && TypeAmountTransfer[amountInfo.type]}
        <br />
        Precio km: ${amountInfo.price_km_trip}
        <br />
        Valor viaje: $
        {amountInfo.base_value && Math.trunc(amountInfo.base_value)}
        <br />
        Minutos por día: {amountInfo.minutes_day}
        <br />
        Salario extrapolado: $
        {amountInfo.extrapolated_salary &&
          Math.trunc(amountInfo.extrapolated_salary)}
        <br />
        Coeficiente salario: {amountInfo.salary_coeficient?.toFixed(2)}
        <br />
        Precio minuto: ${amountInfo.price_minute_trip}
        <br />
        {amountInfo.minimum_wait && (
          <>
            Espera mínima: {amountInfo.minimum_wait}min
            <br />
          </>
        )}
        {amountInfo.price_minute_wait && (
          <>
            Precio minuto espera: ${amountInfo.price_minute_wait}
            <br />
          </>
        )}
        {amountInfo.price_minimum_trip && (
          <>
            Precio mínimo del viaje: ${amountInfo.price_minimum_trip}
            <br />
          </>
        )}
        Salario mínimo: ${amountInfo.minimum_salary}
      </>
    );

  return (
    <>
      <span>${amount} </span>
      <TextWithTooltip placement="right" textTooltip={text}>
        <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
      </TextWithTooltip>
    </>
  );
};

const AmountInfoWait = ({ info }: { info: any }) => {
  if (info.status !== undefined) {
    const amountInfo = info.info.amount;
    const recordWaitStarted = info.status?.history.stages.find(
      (v: any) => v.stage === TransferStage.waiting_return_request
    );
    const recordReturnRequested = info.status?.history.stages.find(
      (v: any) => v.stage === TransferStage.return_requested
    );
    const amountTimeWaitingRequest =
      moment(recordReturnRequested?.time.toDate()).diff(
        recordWaitStarted?.time.toDate(),
        "minutes"
      ) > 60
        ? moment(recordReturnRequested?.time.toDate()).diff(
            recordWaitStarted?.time.toDate(),
            "minutes"
          ) > 690
          ? 690
          : moment(recordReturnRequested?.time.toDate()).diff(
              recordWaitStarted?.time.toDate(),
              "minutes"
            ) - 60
        : false;

    if (!amountInfo) return <></>;

    const text = (
      <>
        <b>TIEMPO ESPERA MÍNIMO SUPERADO</b>
        <br />
        {amountInfo.extra_wait && (
          <>
            Espera extra realizada: {amountInfo.extra_wait}min
            <br />
          </>
        )}
        {amountInfo.price_minute_wait && (
          <>
            Precio minuto espera: ${amountInfo.price_minute_wait}
            <br />
          </>
        )}
      </>
    );

    const amount =
      amountTimeWaitingRequest &&
      amountInfo.price_minute_wait &&
      amountTimeWaitingRequest * amountInfo.price_minute_wait;
    if (!amount) return <></>;

    return (
      <>
        <span> + ${Math.ceil(amount)} </span>
        <TextWithTooltip placement="right" textTooltip={text}>
          <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
        </TextWithTooltip>
      </>
    );
  } else {
    return <></>;
  }
};

export const ExtraAdditionals = ({
  info,
  challengeType,
  base,
  tolls,
}: {
  info: TransferInformation;
  challengeType?: ChallengeType | false;
  base: Number;
  tolls?: Tolls | false;
}) => {
  let amount = 0;
  let tollsAmount = 0;
  if (info.amount?.addition) {
    amount += info.amount.addition;
  }
  if (info.amount?.additional_K) {
    amount += info.amount.additional_K;
  }
  if (info.amount?.additional_E) {
    amount += info.amount.additional_E;
  }
  if (info.amount?.additional_C) {
    amount += info.amount.additional_C;
  }
  if (info.amount?.additional_5M) {
    amount += info.amount.additional_5M;
  }
  if (info.amount?.additional_G) {
    amount += info.amount.additional_G;
  }
  if (tolls) {
    tollsAmount += calculateAmountTolls(tolls);
    amount += tollsAmount;
  }
  if (!amount) return <></>;

  const text = (
    <>
      <b>Valor Base: ${base}</b>
      <br />
      <b>ADICIONALES</b>
      <br />
      {info.amount?.addition && (
        <>
          {challengeType && `Tipo de Desafío: ${ChallengeType[challengeType]}`}
          <p>Adicional DESAFIO: ${info.amount.addition}</p>
        </>
      )}
      {info.amount?.additional_K && (
        <p>Adicional Kilómetro Muerto: ${info.amount?.additional_K}</p>
      )}
      {info.amount?.additional_E && (
        <p>Adicional Tiempo de Espera: ${info.amount?.additional_E}</p>
      )}
      {info.amount?.additional_C && (
        <p>Adicional Cumplimiento: ${info.amount?.additional_C}</p>
      )}
      {info.amount?.additional_5M && (
        <p>Adicional 5M: ${info.amount?.additional_5M}</p>
      )}
      {info.amount?.additional_G && (
        <p>Adicional G: ${info.amount?.additional_G}</p>
      )}
      {tollsAmount > 0 && <p>Monto Peajes: ${tollsAmount}</p>}
    </>
  );
  return (
    <>
      <span> + ${Math.ceil(amount)} </span>
      <TextWithTooltip placement="right" textTooltip={text}>
        <FontAwesomeIcon icon={faQuestionCircle} color="grey" />
      </TextWithTooltip>
    </>
  );
};

const calculateAmountTolls = (tolls: Tolls) => {
  let amount = 0;

  amount += tolls.data
    .map((t) => {
      return t.amount_corrected !== undefined
        ? Number(t.amount_corrected)
        : Number(t.amount);
    })
    .reduce((a, b) => a + b, 0);
  return amount;
};
export const InfoMail = ({
  show,
  handleClose,
  transfer,
}: {
  show: boolean;
  handleClose: any;
  transfer: ScheduledTransfer | TransferRequest;
}) => {
  console.log(transfer, "transfer1");

  const handleSubmit = async (values: any) => {
    console.log(
      moment(transfer.destination.time.toDate())
        .add(values.minutes, "minutes")
        .toDate()
        .toJSON()
    );
    const data1 = {
      origin: {},
      destination: {},
      passenger: {},
      art: {},
      provider: values.provider,
    };
    const data2 = {
      origin: {},
      destination: {
        time: moment(transfer.destination.time.toDate())
          .add(values.minutes, "minutes")
          .toDate()
          .toJSON(),
      },
      passenger: {},
      art: {},
    };
    const d = { idModule: transfer.module.id, minutes: values.minutes };
    console.log(data1, "data 1", data2, "data2");
    await CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_EDIT(
      transfer.module.id,
      data1
    );
    await CALLABLE_FUNCTIONS.ADMIN_MODULE_TRANSFER_EDIT(
      transfer.module.id,
      data2
    );
    await CALLABLE_FUNCTIONS.ADMIN_MODULE_SEND_REPORT_ART(d);
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          Informar viaje coordinado con paciente con demora
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormSendEmail onSubmit={handleSubmit} />
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
const FormSendEmailArt = ({
  handleSubmit,
  submitSucceeded,
  submitting,
  error,
}: {
  handleSubmit: any;
  submitSucceeded: boolean;
  submitting: boolean;
  error: any;
}) => {
  const [userOptions, setUserOptions] = useState<(WithId & User)[]>([]);
  useEffect(() => {
    getActiveUsers().then((v) =>
      setUserOptions(
        _.sortBy(v, [
          (z) => _.toLower(`${z.surname} ${z.name} ${z.phone.number}`),
        ])
      )
    );
  }, []);
  useEffect(() => {
    getActiveUsers().then((v) =>
      setUserOptions(_.sortBy(v, (z) => z.surname.toLocaleLowerCase()))
    );
  }, []);
  if (submitSucceeded) {
    return <Alert variant="success">Email Enviado</Alert>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Row>{error && <Alert variant="danger">{error}</Alert>}</Row>

      <Row>
        <Col xl={6}>
          <Field
            labelinput={
              <TextWithTooltip textTooltip="Cantidad de minutos de espera del paciente">
                Minutos de Demora
              </TextWithTooltip>
            }
            name="minutes"
            type="number"
          />
        </Col>
        <Col xl={6}>
          <Field
            labelinput={<>Asignar Prestador</>}
            name="provider"
            type="select-text"
          >
            <option label="Seleccione una opción"></option>
            {userOptions.map((e) => (
              <option key={e.id} value={e.id}>
                {e.surname +
                  " " +
                  e.name +
                  " - " +
                  e.phone.area_code +
                  e.phone.number}
              </option>
            ))}
          </Field>
        </Col>
      </Row>
      <p></p>
      <Row>
        <Col>
          <SubmitButton block submitting={submitting} change={change}>
            Agendar e informar Email
          </SubmitButton>
        </Col>
      </Row>
    </form>
  );
};
const FormSendEmail = reduxForm({
  form: "unassigned",
})(FormSendEmailArt);
