import React, { useState } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import ROUTES from "constants/routes";
import {
  Container,
  Button,
  Nav,
  Padding,
  Center,
  Link,
  Spinner,
} from "components/styles";
import firebase from "firebase/app";
import "firebase/auth";
import { Field } from "components/input/field";
import { required } from "controllers/validations";
import { SubmissionError, reduxForm } from "redux-form";

const ResetPasswordPage = () => {
  const [loading, setLoading] = useState(false);

  const submit = async (values: any) => {
    setLoading(true);
    await firebase
      .auth()
      .sendPasswordResetEmail(values.email)
      .then(
        () => {
          console.log("hola?");
          return setLoading(false);
        },
        (e) => {
          throw new SubmissionError({ _error: e });
        }
      );
  };
  if (loading) return <Spinner />;

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xl={5}>
          <Padding pad="20px">
            <Center>
              <img
                alt="logo"
                style={{ height: "150px" }}
                src="../assets/logo-070b76280dc0f019580a20151dd1e9515757bbfa7650b5d4955ec4eea815fab1.png"
              />
            </Center>
          </Padding>

          <FormWithRedux onSubmit={submit} />
          <hr />
          <Row>
            <Col>
              <Link to={ROUTES.SIGN_IN}>
                <Button variant="secondary" block>
                  Ingresar
                </Button>
              </Link>
            </Col>
            <Col>
              <Link to={ROUTES.SIGN_UP}>
                <Button variant="info" block>
                  Registrate
                </Button>
              </Link>
            </Col>
          </Row>
          <hr />
          <Nav>
            <Nav.Item>
              <Link to={ROUTES.SEND_INSTRUCTIONS}>
                ¿No recibiste las instrucciones para confirmar?
              </Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
};

const Form = ({ handleSubmit, submitSucceeded }: any) => {
  if (submitSucceeded)
    return (
      <Alert variant="success">
        Le hemos enviado un mail para reiniciar tu clave de acceso
      </Alert>
    );
  return (
    <form onSubmit={handleSubmit}>
      <Field
        type="email"
        name="email"
        placeholder="Email"
        validate={required}
      />

      <Button variant="primary" type="submit" block>
        Enviar instrucciones para resetear contraseña
      </Button>
    </form>
  );
};

const FormWithRedux = reduxForm({
  form: "reset-password",
})(Form);

export default ResetPasswordPage;
