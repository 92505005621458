import { Center, Padding } from "components/styles";
import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { HeaderLandingSubpages } from "../Privacy";

const FAQ = () => (
  <Container>
    <HeaderLandingSubpages />
    <GralContainer>
      <Padding pad={"30px"}>
        <Center>
          <h5 className="font-weight-bold my-4">PREGUNTAS FRECUENTES</h5>
        </Center>

        <Center>
          <h6 className="my-4 font-weight-bold">
            1. REQUISITOS PARA LA INSCRIPCIÓN
          </h6>
        </Center>

        <h6 className="font-weight-bold mb-3">
          1.1 ¿Tengo que ser monotributista para ser conductor en Satapp?
        </h6>

        <p>
          En nuestro sistema tu cuenta debe contar con un monotributo personal y
          activo para emitir factura de los viajes que realices. Y sea
          simultáneamente titular de la cuenta bancaria a la cual se transfiere
          el dinero. Para poder cobrar los viajes que realizaste en tiempo y
          forma, es necesario que realices la delegación y el punto de venta en
          AFIP
        </p>

        <p>
          Para poder cobrar los viajes que realizaste en tiempo y forma,{" "}
          <b>
            es necesario que realices la delegación y el punto de venta en AFIP
          </b>
        </p>

        <h6 className="font-weight-bold mb-3">
          1.2. ¿Cuándo se realizan los pagos de los viajes realizados?
        </h6>
        <p>
          Cobras los viajes que realices todas las semanas, los días miércoles y
          jueves por transferencia bancaria.
        </p>
        <p>
          Para esto es necesario que seas monotributista y hagas la delegación y
          el punto de venta en la afip
        </p>

        <h6 className="font-weight-bold mb-3">
          1.3. ¿Cómo se facturan los viajes para que puedan ser abonados?
        </h6>
        <p>
          Ofrecemos el beneficio de poder delegar la gestión de tu facturación
          ante AFIP en Satapp. De esta manera cada vez que finaliza un viaje,
          Satapp emite la factura y ya está lista para ser pagada. En todo
          momento contás con acceso a tu cuenta para verificar tus viajes
          realizados y facturados.
        </p>

        <Center>
          <h6 className="my-4 font-weight-bold">
            {" "}
            2. SOBRE SATAPP Y SU FUNCIONAMIENTO
          </h6>
        </Center>

        <h6 className="font-weight-bold mb-3">
          2.1. ¿Satapp me exige horas mínimas de conexión?
        </h6>
        <p>
          No, Satapp es totalmente flexible. Cada conductor elige cuándo, cuánto
          y dónde quiere conectarse.
        </p>

        <h6 className="font-weight-bold mb-3">
          2.2. ¿Cuál es la zona de operación de Satapp?
        </h6>
        <p>Satapp opera en zona sur, norte, oeste y CABA</p>

        <h6 className="font-weight-bold mb-3">
          2.3 ¿Cómo se reconocen los km muertos?
        </h6>
        <p>
          En SATAPP reconocemos{" "}
          <b>las distancias recorridas sin paciente a bordo!</b>💪⁣
        </p>

        <p>
          Es decir, el sistema calcula la distancia entre tu locación 🚗 y la
          dirección de origen 🏠 abonando hasta <b>8 km</b>.
        </p>
        <p>
          <b>Aclaración:</b> ⁣La app ya toma en cuenta la distancia a bordo sin
          paciente reconociendo 4 km en el valor del viaje publicado.
        </p>
        <p>
          <b>Ejemplo 1:</b> INICIAS EL TRASLADO y la distancia sin paciente a
          bordo es de 8km.
          <br /> El total a pagar va a ser de 4km ( 8km - 4km ya incluidos en el
          valor del viaje publicado){" "}
        </p>
        <p>
          <b>Ejemplo 2:</b> INICIAS EL TRASLADO y la distancia recorrida sin
          paciente es de 25km.
          <br />
          <b>El total a pagar es de 8km.</b>
        </p>
        <p>
          Tene en cuenta que la app <b>empieza a contar la distancia</b> una vez
          que apretas <b>INICIAR</b>
        </p>

        <h6 className="font-weight-bold mb-3">2.4. ¿Reintegran peajes?</h6>
        <p>
          Si, se reintegran peajes. Ahora los peajes están <b>PRECALCULADOS</b>
        </p>
        <ul>
          <li>
            Siempre considera{" "}
            <b>valores de telepeaje, puede variar en hora pico o no pico</b>
          </li>
          <li>
            Se basa en <b>el recorrido que marca la app</b>
          </li>
        </ul>
        <p>
          Si programaste un viaje en el cual incluye peaje y al finalizar
          aparece Error de peaje, pronto va a aparecer el monto correspondiente
          ➡️ (ese recorrido tiene peaje).
        </p>
        <p>
          En caso de que el recorrido marce ir por autopista y no veas el peaje
          reflejado en el detalle, contactate con nuestras operadoras
        </p>
        <h6 className="font-weight-bold mb-3">
          2.5. ¿De cuánto es la comisión de Satapp?
        </h6>
        <p>Satapp no cobra comisión. Todo lo que trabajas es tuyo.</p>
        <h6 className="font-weight-bold mb-3">
          2.6. Mi auto es un Taxi, ¿Puedo trabajar igual con Satapp?
        </h6>
        <p>Si aceptamos.</p>
        <h6 className="font-weight-bold mb-3">
          2.7. ¿Es importante la reputación del conductor?
        </h6>
        <p>
          Los traslados son de pacientes que acuden a un turno médico, o a un
          tratamiento. Por lo tanto, llegar tarde significa un gran perjuicio
          para el paciente y va en contra de la filosofía de la empresa. Los
          conductores que cumplan sus traslados siempre a tiempo, tendrán
          preferencia para seleccionar sus viajes.
        </p>

        <h6 className="font-weight-bold mb-3">
          2.8. ¿Puedo tener varios autos dentro de mi Perfil?
        </h6>
        <p>
          Satapp te permite registrar todos los autos que quieras dentro de tu
          Perfil como Conductor Particular. Para ver como se hace{" "}
          <a
            href="https://drive.google.com/file/d/1gkMMoLx66qz9yKxmRWOrDlnu0Wz9GDS_/view?usp=sharing"
            target="_blank"
          >
            click aquí
          </a>
        </p>
        <p>
          Al momento de abrir la aplicación podrás elegir con cuál auto de tu
          lista de autos validados vas a realizar el viaje.
        </p>

        <Center>
          <h6 className="my-4 font-weight-bold">
            3. SOBRE EL PROCESO DE INSCRIPCIÓN
          </h6>
        </Center>

        <h6 className="font-weight-bold mb-3">
          3.1. ¿Cómo puedo registrarme en la app?
        </h6>
        <p>
          El registro es 100% online. Podes registrarte en{" "}
          <a target="_blank" href={"https://www.satapp.com.ar"}>
            <b>SATAPP</b>
          </a>{" "}
          llenando el formulario. Mira cómo registrarte{" "}
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1hnsxkhMbyOh1u8NDHLrVGIcFacHmycqm/view?usp=sharing"
          >
            aquí
          </a>
        </p>

        <h6 className="font-weight-bold mb-3">
          3.2. ¿Una vez que tengo aprobado el perfil puedo agendar traslado?
        </h6>
        <p>
          Una vez aprobado el perfil, podrás entrar a la app y visualizar los
          viajes disponibles y el funcionamiento de la misma. Pero no podrás
          agendarte viajes hasta que realices la delegación y generes el punto
          de venta en afip. Para saber cómo generar la delegación y punto de
          venta en afip hace{" "}
          <a
            target="_blank"
            href="https://drive.google.com/file/d/1OXfEHXDth_oXEaRa8BZpLzcfCa-apB25/view?usp=sharing"
          >
            click aquí
          </a>
          .
        </p>
      </Padding>
    </GralContainer>
  </Container>
);

const GralContainer = styled.div`
  background-color: white;
  color: #00426a;
  font-family: Quicksand;
`;

export default FAQ;
